import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Style.css";
import {
  CheckForLight,
  removeQuestionMark,
} from "../util/common/CommanFunction";
/*------------------------- MUI components ------------- */
import {
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  Tooltip,
  Stack,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  Modal,
  Autocomplete,
  TextField,
  Divider,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import IconButton from "@mui/material/IconButton";
/*------------------------ MUI Icon ----------------------- */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import SubtitlesOffIcon from "@mui/icons-material/SubtitlesOff";
import CloseIcon from "@mui/icons-material/Close";
import errorMsg from "../util/errorMessage.json";
import CustomSnackbar from "../util/components/CustomSnackbar";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
/*---------------------------- API icons -------------------------*/
import {
  fetchViewticket,
  AssignlistDropdown,
  putTicketReassignEdit,
  putTicketholdEdit,
  putTicketcloseEdit,
  putTicketupdateEdit,
  fetchDownloadDetails,
  fetchTicketActivity,
} from "../../Api/TicketApi";
import CustomBreadcrumbs from "../util/components/CustomBread";
import { decryptPaddedQuery } from "../util/security/Cipher";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
};

const TicketView = () => {
  /*------------- Location Id  get call ------------ */
  const location = useLocation();
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`?${queryParam}`);
  const params = {};
  console.log(params);
console.log(location);

  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }
  const isFromSiteList =
    Object.keys(params).length > 0 || location.state?.isFromSiteList
    console.log(isFromSiteList);
    
    const isFromSticketcalender =
    Object.keys(params).length > 0 || location.state?.isFromSticketcalender
    console.log(isFromSticketcalender);
    
  const ticketId = params.ticketId || location.state?.id?.ticketId;
  const ticketIdd = params.siteId || location.state?.id?.siteId;
  const navigate = useNavigate();
  /*----------------- UseState  for data -----------*/
  const [loading, setloading] = useState(true);
  const [activeSection, setActiveSection] = useState("summary");
  const [view, setView] = useState([]);
  const [ticketActivity, setTicketActivity] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [value, setValue] = useState();
  const [open, setOpen] = useState(false);
  const [handleHold, setHandleHoldOpen] = useState(false);
  const [closeTicketModel, setCloseTicketOpen] = useState(false);
  const [assign, setassignedValue] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [assignedData, setAssignData] = useState(null);
  const [edit, setedit] = useState(false);
  const [fromtime, setFromTime] = useState("00:00");
  const initialSnack = { open: false, severity: "error", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const [toBackend, setToBackend] = useState(false);
  const initalState = {
    close_remark: "",
    hold_remark: "",
    assign_remarks: "",
    assignedTo: "",
    scheduleOn: "",
  };
  const dayjs = require("dayjs");
  const [fieldValue, setFieldValue] = useState(initalState);

  const paths = isFromSiteList
  ? [
      { label: "Home", path: "/menu" },
      { label: "Site List", path: -1 },
      { label: "Ticket Configuration", path: -1 },
      { label: "View Ticket", path: "view_ticket" },
    ]
  : isFromSticketcalender
  ? [
      { label: "Home", path: "/menu" },
      { label: "Ticket Calendar", path: "/menu/ticket_calender" },
      { label: "View Ticket", path: "view_ticket" },
    ]
  : [
      { label: "Home", path: "/menu" },
      { label: "Ticket Configuration", path: "/menu/ticket" },
      { label: "View Ticket", path: "view_ticket" },
    ];


  useEffect(() => {
    getTicketValue();
    fetchAssigntype();
    // getTicetActivity();
  }, []);

  const handleErrorClose = () => {
    setAssignData([]);
    setSelectedDate(null);
    setFieldValue(initalState);
  };
  const handleChangeFromTime = (event) => {
    console.log(event, "event");
    const selectedFromTime = dayjs(event);
    const timeString = new Date(selectedFromTime).toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
    console.log(timeString, "selectedFromTime");
    setFromTime(timeString);
  };
  const handleClose = () => {
    setOpen(false);
    setAssignData([]);
    setSelectedDate(null);
    setFieldValue(initalState);
  };
  const handleOpen = () => {
    setOpen(true);
    setedit(true);
  };
  const handleOpenAssign = () => {
    setOpen(true);
    setedit(false);
    setFieldValue("");
  };
  const handleCloseAssign = () => setOpen(false);
  const handleHoldOpen = () => setHandleHoldOpen(true);
  const handleHoldClose = () => setHandleHoldOpen(false);
  const handleCloseTicketOpen = () => setCloseTicketOpen(true);
  const handleCloseTicketClose = () => setCloseTicketOpen(false);

  const handleCloseRemark = (e) => {
    setFieldValue({ ...fieldValue, [e.target.name]: e.target.value });
  };
  const handleHoldRemark = (e) => {
    setFieldValue({ ...fieldValue, [e.target.name]: e.target.value });
  };
  const handleChangevalue = (e) => {
    setFieldValue({ ...fieldValue, [e.target.name]: e.target.value });
  };

  const handleSectionClick = (value) => {
    setActiveSection(value);
  };
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  const handleDateChange = (date) => {
    const selectedDate = new Date(date);
    console.log(selectedDate);

    selectedDate.setDate(selectedDate.getDate() + 1);
    setSelectedDate(selectedDate);
  };

  const handleassign = (data, value) => {
    if (value === null) {
      setAssignData("");
    } else {
      setAssignData(value);
    }
  };

 

  useEffect(() => {
    if (!ticketId) return;

    const getTicetActivity = async () => {
      setloading(true);
      try {
        const data = await fetchTicketActivity(ticketId);
        setTableData(data); 
      } catch (err) {
      } finally {
        setloading(false);
      }
    };
    getTicetActivity();
  }, [ticketId]);

  const getTicketValue = async () => {
    try {
      const data = await fetchViewticket(ticketId);
      if (data) {
        const sortedTransactions =
          data.ticketTransactions?.sort(
            (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
          ) || [];
        setValue(sortedTransactions);
        setView(data);
      }
    } catch (e) {
      console.error("Error fetching ticket data:", e);
    }
  };

  const fetchAssigntype = async () => {
    try {
      let data = await AssignlistDropdown(ticketIdd);
      setassignedValue(data);
    } catch (e) {
      console.error(e);
    }
  };
  const handleReassignUpdate = async () => {
    try {
      setToBackend(true);
      let assignIdFilter = assign?.filter(
        (data) => data.userName === assignedData
      );
      let assignedTo =
        assignIdFilter.length > 0 ? assignIdFilter[0]?.userId : null;
      console.log(selectedDate);
      console.log(fromtime);

      let scheduleOn = selectedDate
        ? selectedDate.toISOString().substring(0, 10) + " " + fromtime
        : "";

      if (scheduleOn === "") {
        setSnack(errorMsg.unique.scheduleOn);
        setToBackend(false);
        return;
      }
      if (!assignedTo) {
        setSnack(errorMsg.unique.assignedTo);
        setToBackend(false);
        return;
      }
      let data = {
        assignedTo: assignedTo,
        scheduleOn: scheduleOn,
        remarks: fieldValue?.assign_remarks,
      };
      console.log(data);

      let responsedata = await putTicketReassignEdit(ticketId, data);

      if (responsedata.status === 200) {
        getTicketValue();
        setOpen(false);
        fetchAssigntype();
        setFromTime("00:00");
        setToBackend(false);
        setSnack(errorMsg.success);
        handleClose();
      }
    } catch (error) {
      setSnack(errorMsg.failure);
      handleErrorClose();
      console.error("Error:", error);
    }
  };

  const handleassignUpdate = async () => {
    try {
      setToBackend(true);
      let assignIdFilter = assign?.filter(
        (data) => data.userName === assignedData
      );
      let assignedTo =
        assignIdFilter.length > 0 ? assignIdFilter[0]?.userId : null;
      let scheduleOn = selectedDate
        ? selectedDate.toISOString().substring(0, 10) + " " + fromtime
        : "";

      if (scheduleOn === "") {
        setSnack(errorMsg.unique.scheduleOn);
        setToBackend(false);
        return;
      }
      if (!assignedTo) {
        setSnack(errorMsg.unique.assignedTo);
        setToBackend(false);
        return;
      }
      let data = {
        assignedTo: assignedTo,
        scheduleOn: scheduleOn,
        remarks: fieldValue?.assign_remarks,
      };
      let responsedata = await putTicketupdateEdit(data, ticketId);
      if (responsedata.status === 200) {
        getTicketValue();
        setToBackend(false);
        setOpen(false);
        fetchAssigntype();
        setSnack(errorMsg.success);
        setFromTime("00:00");
        handleClose();
      }
    } catch (error) {
      setSnack(errorMsg.failure);
      setToBackend(false);
      handleErrorClose();
      console.error("Error:", error);
    }
  };
  const handlecloseUpdate = async () => {
    try {
      let data = {
        remarks: fieldValue?.close_remark,
      };
      let responsedata = await putTicketcloseEdit(ticketId, data);
      if (responsedata.status == 200) {
        getTicketValue();
        fetchAssigntype();
        setCloseTicketOpen(false);
        setSnack(errorMsg.success);
      }
    } catch (error) {
      setSnack(errorMsg.failure);
      console.error("Error:", error);
    }
  };
  const handleClosedownload = async () => {
    console.log(ticketId);

    let data = await fetchDownloadDetails(ticketId);
  };
  const handleholdUpdate = async () => {
    try {
      let data = {
        remarks: fieldValue?.hold_remark,
      };
      let responsedata = await putTicketholdEdit(ticketId, data);

      if (responsedata.status == 200) {
        getTicketValue();
        setHandleHoldOpen(false);
        fetchAssigntype();
        setSnack(errorMsg.success);
      }
    } catch (error) {
      setSnack(errorMsg.failure);
      console.error("Error:", error);
    }
  };
  let backgroundColor;
  if (CheckForLight()) {
    backgroundColor = activeSection === "summary" ? "" : "rgb(246, 248, 252)";
  } else {
    backgroundColor = activeSection === "summary" ? "" : "#4f4f4f";
  }

  let backgroundColorr;
  if (CheckForLight()) {
    backgroundColorr = activeSection === "activity" ? "" : "white";
  } else {
    backgroundColorr = activeSection === "activity" ? "" : "#4f4f4f";
  }

  return (
    <div clasName="view-ticket">
      <CustomSnackbar
        open={snack.open}
        onClose={handleSnackClose}
        autoHideDuration={5000}
        severity={snack.severity}
        message={snack.message}
      />
      <div>
        <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Paper
            elevation={0}
            style={{
              width: "100%",
              borderRadius: "2px 2px 0px 0px",
              userSelect: "none",
              height: "5vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid
              container
              width={"100%"}
              direction="row"
              justifyContent="space-between"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid
                item
                xs={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <CustomBreadcrumbs
                  paths={paths || []}
                  separatorSize="18px"
                  fontSize="14px"
                />
              </Grid>

              <Grid
                item
                xs="auto"
                style={{ display: "flex", alignItems: "center" }}
              >
                {view?.statusName !== "Created" &&
                view?.stateName !== "Closed" ? (
                  <div>
                    <Tooltip title="Re-assign ">
                      <SupervisorAccountIcon
                        onClick={handleOpen}
                        style={{
                          marginRight: "10px",
                          marginLeft: "10px",
                          cursor: "pointer",
                          color: "#004AFF",
                          fontSize: "25px",
                        }}
                      />
                    </Tooltip>
                  </div>
                ) : null}

                {view?.stateName !== "Closed" && view?.stateName !== "Hold" ? (
                  <div>
                    <Tooltip title="on-Hold ">
                      <ConfirmationNumberIcon
                        onClick={handleHoldOpen}
                        style={{
                          marginRight: "10px",
                          marginLeft: "10px",
                          cursor: "pointer",
                          color: "#004AFF",
                          fontSize: "25px",
                        }}
                      />
                    </Tooltip>
                  </div>
                ) : null}
                {view?.stateName === "Closed" ? null : (view?.stateName !==
                    "Closed" &&
                    view?.statusName === "Unfinished") ||
                  view?.statusName === "Finished" ? (
                  <div>
                    <Tooltip title="Close">
                      <SubtitlesOffIcon
                        onClick={handleCloseTicketOpen}
                        style={{
                          marginRight: "10px",
                          marginLeft: "10px",
                          color: "#004AFF",
                          cursor: "pointer",
                          fontSize: "25px",
                        }}
                      />
                    </Tooltip>
                  </div>
                ) : null}

                {view?.statusName === "Unfinished" ||
                view?.statusName === "Finished" ? (
                  <div>
                    <Tooltip title="download">
                      <SaveAltIcon
                        onClick={handleClosedownload}
                        style={{
                          marginRight: "10px",
                          marginLeft: "10px",
                          color: "#004AFF",
                          cursor: "pointer",
                          fontSize: "25px",
                        }}
                      />
                    </Tooltip>
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </div>

      <div className="view-ticket-card">
        <div className="Button-ticket">
          <Stack direction="column" spacing={3}>
            <Button
              elevation={22}
              variant="contained"
              style={{
                textTransform: "capitalize",
                width: "14vw",
                color: activeSection === "summary" ? "white" : "black",
                background: backgroundColor,
              }}
              onClick={() => handleSectionClick("summary")}
            >
              Ticket Summary
            </Button>
            <Button
              variant="contained"
              elevation={22}
              style={{
                textTransform: "capitalize",
                color: activeSection !== "summary" ? "white" : "black",
                background: backgroundColorr,
              }}
              onClick={() => handleSectionClick("activity")}
            >
              Ticket Activity
            </Button>
          </Stack>
        </div>
        <div className="Ticket-details">
          {activeSection === "summary" ? (
            <div>
              <Grid container spacing={3} columns={32}>
                <Grid item xs={32}>
                  <Box style={{ width: "100%", borderRadius: "20px" }}>
                    <Paper
                      sx={{ width: "100%", mb: 2, borderRadius: "10px" }}
                      elevation={0}
                    >
                      <div style={{ padding: "10px 10px 10px 10px" }}>
                        {/* <Grid
                          container
                          spacing={4}
                          columns={32}
                          style={{ padding: "15px 10px 15px 10px" }}
                        >
                          <Grid item xs={28}>
                            <Typography
                              style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                lineHeight: "16px",
                                userSelect: "none",
                                textTransform: "capitalize",
                              }}
                            >
                              Ticket Details
                            </Typography>
                          </Grid>

                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Ticket No
                            </Typography>
                            <Typography>{view?.ticketCode||"N/A"} </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Status
                            </Typography>
                            <Typography>{view?.statusName}</Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Site Name
                            </Typography>
                            <Typography>{view?.siteName}</Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Type
                            </Typography>
                            <Typography>{view?.ticketTypeName}</Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Category
                            </Typography>
                            <Typography>{view?.categoryName}</Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Equipment Name
                            </Typography>
                            <Typography>{view?.displayName}</Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Priority
                            </Typography>
                            <Typography>
                              {" "}
                              {view?.priority === 3
                                ? "High"
                                : view?.priority === 2
                                ? "Medium"
                                : "Low"}
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Assigned To
                            </Typography>
                            {!view?.assignedBy ||
                            view?.assignedBy.length === 0 ? (
                              <div
                                style={{
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                                onClick={handleOpenAssign}
                              >
                                Assign
                              </div>
                            ) : (
                              <Typography>{view?.assignedBy}</Typography>
                            )}
                          </Grid>
                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Subject
                            </Typography>
                            <Typography>{view?.subject}</Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Schedule On
                            </Typography>
                            {!view?.scheduleOn ||
                            view?.scheduleOn.length === 0 ? (
                              <div style={{ marginLeft: "20%" }}>-</div>
                            ) : (
                              <Typography>
                                {view?.scheduleOn
                                  ? (() => {
                                      const originalDate = new Date(
                                        view.scheduleOn
                                      );
                                      const adjustedDate = new Date(
                                        originalDate.getTime() -
                                          5 * 60 * 60 * 1000 -
                                          30 * 60 * 1000
                                      );
                                      return `${adjustedDate
                                        .toLocaleDateString("en-GB", {
                                          day: "2-digit",
                                          month: "2-digit",
                                          year: "numeric",
                                        })
                                        .replace(
                                          /\//g,
                                          "/"
                                        )} ${adjustedDate.toLocaleTimeString(
                                        "en-GB",
                                        {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: false,
                                        }
                                      )}`;
                                    })()
                                  : "-"}
                              </Typography>
                            )}
                          </Grid>

                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Created Date
                            </Typography>
                            {view?.createdDate
                              ? new Date(view.createdDate)
                                  .toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })
                                  .replace(/\//g, "/")
                              : ""}
                          </Grid>

                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Created By
                            </Typography>
                            <Typography>{view?.userName}</Typography>
                          </Grid>
                        </Grid> */}
                        <Grid
                          container
                          spacing={4}
                          columns={32}
                          style={{ padding: "15px 10px 15px 10px" }}
                        >
                          <Grid item xs={28}>
                            <Typography
                              style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                lineHeight: "16px",
                                userSelect: "none",
                                textTransform: "capitalize",
                              }}
                            >
                              Ticket Details
                            </Typography>
                          </Grid>

                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Ticket No
                            </Typography>
                            <Typography>{view?.ticketCode || "N/A"}</Typography>
                          </Grid>

                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Status
                            </Typography>
                            <Typography>{view?.statusName || "N/A"}</Typography>
                          </Grid>

                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Site Name
                            </Typography>
                            <Typography>{view?.siteName || "N/A"}</Typography>
                          </Grid>

                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Type
                            </Typography>
                            <Typography>
                              {view?.ticketTypeName || "N/A"}
                            </Typography>
                          </Grid>

                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Category
                            </Typography>
                            <Typography>
                              {view?.categoryName || "N/A"}
                            </Typography>
                          </Grid>

                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Equipment Name
                            </Typography>
                            <Typography>
                              {view?.displayName || "N/A"}
                            </Typography>
                          </Grid>

                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Priority
                            </Typography>
                            <Typography>
                              {view?.priority === 3
                                ? "High"
                                : view?.priority === 2
                                ? "Medium"
                                : view?.priority === 1
                                ? "Low"
                                : "N/A"}
                            </Typography>
                          </Grid>

                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Assigned To
                            </Typography>
                            {!view?.assignedBy ? (
                              <div
                                style={{
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                                onClick={handleOpenAssign}
                              >
                                Assign
                              </div>
                            ) : (
                              <Typography>{view?.assignedBy}</Typography>
                            )}
                          </Grid>

                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Subject
                            </Typography>
                            <Typography>{view?.subject || "N/A"}</Typography>
                          </Grid>

                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Schedule On
                            </Typography>
                            <Typography>
                              {view?.scheduleOn
                                ? (() => {
                                    const date = new Date(view.scheduleOn);
                                    return `${date.toLocaleDateString("en-GB", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    })} ${date.toLocaleTimeString("en-GB", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: false,
                                    })}`;
                                  })()
                                : "N/A"}
                            </Typography>
                          </Grid>

                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Created Date
                            </Typography>
                            <Typography>
                              {view?.createdDate
                                ? new Date(view.createdDate).toLocaleDateString(
                                    "en-GB",
                                    {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    }
                                  )
                                : "N/A"}
                            </Typography>
                          </Grid>

                          <Grid item xs={8}>
                            <Typography style={{ color: "gray" }}>
                              Created By
                            </Typography>
                            <Typography>{view?.userName || "N/A"}</Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  </Box>
                </Grid>
                <Grid item xs={25}></Grid>
              </Grid>
            </div>
          ) : (
            <div className="table-container-wrapper">
              <TableContainer component={Paper}>
                <Table aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>

                      <TableCell align="center">Ticket Activity</TableCell>
                      <TableCell align="center">Remarks</TableCell>
                      <TableCell align="center">Last Acted By </TableCell>
                      <TableCell align="center">Scheduled On </TableCell>
                      <TableCell align="center">Last Acted On </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {value.map((row, index) => ( */}
                    {/* {ticketActivity?.map((row, index) => ( */}
                    {tableData?.map((row, index) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{++index}</TableCell>

                        <TableCell align="center">
                          {row.description === "Ticket has been Created"
                            ? row.description
                            : row.description + "-" + row.assignedBy}
                        </TableCell>
                        <TableCell align="center">
                          {row.description === "Ticket has been Created"
                            ? row.description
                            : row.remarks}
                        </TableCell>
                        <TableCell align="center">{row.userName}</TableCell>
                        <TableCell align="center">
                          {row?.scheduleOn
                            ? (() => {
                                const originalDate = new Date(view.scheduleOn);
                                const adjustedDate = new Date(
                                  originalDate.getTime() -
                                    5 * 60 * 60 * 1000 -
                                    30 * 60 * 1000
                                );
                                return `${adjustedDate
                                  .toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })
                                  .replace(
                                    /\//g,
                                    "/"
                                  )} ${adjustedDate.toLocaleTimeString(
                                  "en-GB",
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: false,
                                  }
                                )}`;
                              })()
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row.lastUpdatedDate
                            ? new Date(row.lastUpdatedDate)
                                .toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                })
                                .replace(/\//g, "-")
                            : ""}
                        </TableCell>
                      </TableRow>
                    ))}
                    {/* {value.map((row, index) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{++index}</TableCell>

                        <TableCell align="center">
                          {row.description === "Ticket has been Created"
                            ? row.description
                            : row.description + "-" + row.assignedBy}
                        </TableCell>
                        <TableCell align="center">
                          {row.description === "Ticket has been Created"
                            ? row.description
                            : row.remarks}
                        </TableCell>
                        <TableCell align="center">{row.userName}</TableCell>
                        <TableCell align="center">
                          {row?.scheduleOn
                            ? (() => {
                                const originalDate = new Date(view.scheduleOn);
                                const adjustedDate = new Date(
                                  originalDate.getTime() -
                                    5 * 60 * 60 * 1000 -
                                    30 * 60 * 1000
                                );
                                return `${adjustedDate
                                  .toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })
                                  .replace(
                                    /\//g,
                                    "/"
                                  )} ${adjustedDate.toLocaleTimeString(
                                  "en-GB",
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: false,
                                  }
                                )}`;
                              })()
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {row.lastUpdatedDate
                            ? new Date(row.lastUpdatedDate)
                                .toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                })
                                .replace(/\//g, "-")
                            : ""}
                        </TableCell>
                      </TableRow>
                    ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>

      {/* -------------------------- Re-assign Ticket Model -------------------- */}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: 'center',
                padding: "20px",
                backgroundColor: "rgb(246, 248, 252)",
                borderRadius: "20px",
              }}
            >
              <div>
                {edit ? (
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h4"
                    className="modal-head"
                    style={{ marginTop: "2%", marginLeft: "-6%" }}
                  >
                    Re-assign Ticket
                  </Typography>
                ) : (
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h4"
                    className="modal-head"
                    style={{ marginTop: "2%", marginLeft: "-6%" }}
                  >
                    Assign Ticket
                  </Typography>
                )}
              </div>
              <div>
                <Stack spacing={2} direction="row">
                  <CloseIcon
                    onClick={() => handleClose()}
                    style={{ cursor: "pointer" }}
                  />
                </Stack>
              </div>
            </div>
            <Divider sx={{ borderColor: "#888" }} />
            <div style={{ padding: "20px" }}>
              <div>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  style={{ marginTop: "4%" }}
                >
                  <Grid item xs={5}>
                    <div>
                      <Typography>Date</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          size="small"
                          disablePast
                          sx={{ width: "14vw" }}
                          onChange={handleDateChange}
                          slotProps={{
                            textField: { size: "small", format: "DD/MM/YYYY" },
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </Grid>

                  <Grid item xs={5}>
                    <div>
                      <Typography>AssignedTo</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Autocomplete
                        name="status"
                        disablePortal
                        id="combo-box-demo"
                        onChange={(data, event) => handleassign(data, event)}
                        options={assign.map((options) => options.userName)}
                        size="small"
                        sx={{ width: "14vw" }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder=" Jane" />
                        )}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>Time Slot</Typography>
                  </Grid>

                  <Grid item xs={7}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <div style={{ overflow: "hidden" }}>
                          <TimePicker
                            ampm={false}
                            onChange={(event) => {
                              handleChangeFromTime(event);
                            }}
                            minTime={dayjs().startOf("day").hour(6)}
                            // maxTime={latestToTime}
                            maxTime={dayjs().startOf("day").hour(19)}
                            slotProps={{
                              textField: {
                                size: "small",
                                InputProps: {
                                  style: {
                                    overflow: "hidden",
                                    width: "14vw",
                                  },
                                },
                              },
                            }}
                          />
                        </div>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={5}>
                    <div>
                      <Typography>Remark</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <TextField
                        name="assign_remarks"
                        id="outlined-basic"
                        size="small"
                        placeholder="Remark"
                        sx={{ width: "14vw" }}
                        variant="outlined"
                        onChange={(event) => handleChangevalue(event)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div>
                {edit ? (
                  <div style={{ marginTop: "3%" }}>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        disabled={
                          !(
                            assignedData &&
                            selectedDate &&
                            fieldValue?.assign_remarks
                          )
                        }
                        style={{ textTransform: "capitalize" }}
                        variant="contained"
                        onClick={handleReassignUpdate}
                      >
                        {toBackend ? "Reassign..." : "Re-assign"}
                      </Button>
                    </Stack>
                  </div>
                ) : (
                  <div style={{ marginTop: "2%" }}>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        // disabled={toBackend}
                        disabled={
                          !(
                            assignedData &&
                            selectedDate &&
                            fieldValue?.assign_remarks
                          )
                        }
                        style={{ textTransform: "capitalize" }}
                        variant="contained"
                        onClick={handleassignUpdate}
                      >
                        {toBackend ? "Assigning..." : "Assign "}
                      </Button>
                    </Stack>
                  </div>
                )}
              </div>
            </div>
          </Box>
        </Modal>
      </div>

      {/* -------------------------------On hold Ticket Model ----------------- */}
      <div>
        <Modal
          open={handleHold}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: 'center',
                padding: "20px",
                backgroundColor: "rgb(246, 248, 252)",
                borderRadius: "20px",
              }}
            >
              <div>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h4"
                  className="modal-head"
                  style={{ marginTop: "2%", marginLeft: "-6%" }}
                >
                  Ticket On-Hold
                </Typography>
              </div>
              <div>
                <Stack spacing={2} direction="row">
                  <CloseIcon
                    onClick={() => handleHoldClose()}
                    style={{ cursor: "pointer" }}
                  />
                </Stack>
              </div>
            </div>
            <Divider sx={{ borderColor: "#888" }} />
            <div style={{ padding: "10px" }}>
              <div>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  style={{ marginTop: "4%" }}
                >
                  <Grid item xs={5}>
                    <div>
                      <Typography>Remark</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div>
                      <TextField
                        name="hold_remark"
                        id="outlined-basic"
                        size="small"
                        placeholder="Remark"
                        sx={{ width: "10vw" }}
                        variant="outlined"
                        onChange={(event) => handleHoldRemark(event)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div>
                <div style={{ marginTop: "4%" }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Button
                      disabled={!fieldValue?.hold_remark}
                      style={{ textTransform: "capitalize" }}
                      variant="contained"
                      onClick={handleholdUpdate}
                    >
                      {toBackend ? "Holding..." : "Hold"}
                    </Button>
                  </Stack>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>

      {/* -------------------------------close Ticket  Modal--------------------------- */}
      <div>
        <Modal
          open={closeTicketModel}
          onClose={handleCloseTicketClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: 'center',
                padding: "20px",
                backgroundColor: "rgb(246, 248, 252)",
                borderRadius: "20px",
              }}
            >
              <div>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h4"
                  className="modal-head"
                  style={{ marginTop: "2%", marginLeft: "-6%" }}
                >
                  Ticket Close
                </Typography>
              </div>
              <div>
                <Stack spacing={2} direction="row">
                  <CloseIcon
                    onClick={() => handleCloseTicketClose()}
                    style={{ cursor: "pointer" }}
                  />
                </Stack>
              </div>
            </div>
            <Divider sx={{ borderColor: "#888" }} />
            <div style={{ padding: "30px" }}>
              <div>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  style={{ marginTop: "4%" }}
                >
                  <Grid item xs={5}>
                    <div>
                      <Typography>Remark</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <TextField
                        name="close_remark"
                        id="outlined-basic"
                        size="small"
                        placeholder="Remark"
                        sx={{ width: "10vw" }}
                        variant="outlined"
                        onChange={(event) => handleCloseRemark(event)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div style={{ marginTop: "2%" }}>
                <Stack
                  direction="row"
                  spacing={2}
                  style={{ justifyContent: "flex-end" }}
                >
                  <Button
                    disable={!fieldValue?.close_remark}
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                    onClick={handlecloseUpdate}
                  >
                    Close
                  </Button>
                </Stack>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};
export default TicketView;
