import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { IconButton } from "@mui/material";
import moment from "moment";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

export const LargeChartEiraVersion = (props) => {
  const {
    tableData,
    chartName,
    fallBack,
    WidgetName,
    from,
    isDashboard,
    customName,
    isStacked,
    seriesData,
    mode,
  } = props;

  const [showTable, setShowTable] = React.useState(false);
  const [tableContent, setTableContent] = React.useState("");
  const [chartOptions, setChartOptions] = React.useState({});
  const [isLight, setIsLight] = React.useState(mode === "light" ? true : false);
  console.log(props, "props");

  useEffect(() => {
    setIsLight(mode === "light" ? true : false);
  }, [mode]);

  const nameForFile = isDashboard ? customName : WidgetName;

  useEffect(() => {
    const chartOptions = {
      //chart details
      //title
      title: {
        text: WidgetName,
        // align: "left",
        // x: 60,
        style: {
          textTransform: "captalize",
          color: "#544fc5",
          fontWeight: "normal",
          fontSize: "1.5em",
        },
      },

      //chart config
      chart: {
        type: "column",
        backgroundColor: isLight ? "white" : "#121212",
      },

      //x axis config
      xAxis: {
        type: chartName !== "Active Power" ? "category" : "datetime",
        labels: {
          format: "{value:%H:%M}",
          style: {
            color: "#1773A6",
          },
        },
        title: {
          text: WidgetName.includes("Daily")
            ? "Time"
            : WidgetName.includes("Tree")
            ? "Month"
            : WidgetName.includes("Total")
            ? "Year"
            : "Date",
          style: {
            color: "#1773A6",
          },
        },
      },

      //y axis config
      yAxis: [
        {
          // 0
          title: {
            text: `Energy Generation (kWh)`,
            style: {
              color: "#1773A6",
              fontSize: isDashboard ? "10px" : "14px",
            },
          },
          visible: seriesData?.some((item) => item.name === "Today Energy"),
          style: {
            color: "#1773A6",
            fontSize: isDashboard ? "10px" : "0.8em",
            fontFamily: "Inter",
          },
          labels: {
            style: {
              color: "#1773A6",
              fontSize: isDashboard ? "10px" : "0.8em",
            },
          },
        },
        {
          //1
          min: 0,
          // max: 1000,
          title: {
            text: "Irradiation (Wh/m2)",
            style: {
              color: "#1773A6",
              fontSize: isDashboard ? "10px" : "0.8em",
              fontFamily: "Inter",
            },
          },
          opposite: true,
          visible: seriesData?.some((item) => item.name === "Irradiation"),
          labels: {
            style: {
              color: "#1773A6",
              fontSize: isDashboard ? "10px" : "0.8em",
            },
          },
        },
        {
          //2
          title: {
            text: "Specific Yield",
            color: "#1773A6",
            fontSize: isDashboard ? "10px" : "14px",
          },
          opposite: true,
          visible: chartName === "Specific Yield",
          min: 0,
          labels: {
            style: {
              color: "#1773A6",
              fontSize: isDashboard ? "10px" : "14px",
            },
          },
        },
        {
          // 3
          title: {
            text: "Performance Ratio (%)",
            style: {
              color: "#1773A6",
              fontSize: "0.8em",
              fontFamily: "Inter",
            },
          },
          min: 0,
          opposite: true,
          visible: chartName === "Performance Ratio",
          labels: {
            style: {
              color: "#1773A6",
            },
          },
        },
        {
          //4
          title: {
            text: "Rate",
            style: {
              color: "#1773A6",
              fontSize: "0.8em",
              fontFamily: "Inter",
            },
          },
          min: 0,
          opposite: true,
          visible: chartName === "Revenue Generation",
          labels: {
            style: {
              color: "#1773A6",
            },
          },
        },
        {
          //5
          title: {
            text: `Tree planted`,
            style: {
              color: "#1773A6",
              fontSize: "0.8em",
              fontFamily: "Inter",
            },
          },
          min: 0,
          opposite: false,
          visible: seriesData?.some((item) => item.name === "Tree planted"),
          labels: {
            style: {
              color: "#1773A6",
            },
          },
        },
        {
          //6
          title: {
            text: "Active Power (kW)",
            style: {
              color: "#1773A6",
              fontSize: "0.8em",
              fontFamily: "Inter",
            },
          },
          min: 0,
          opposite: false,
          visible: seriesData?.some((item) =>
            item.name.includes("ActivePower")
          ),
          labels: {
            style: {
              color: "#1773A6",
            },
          },
        },
      ],

      // colors: [
      //   // "#1E90FF",
      //   "#FF5733",
      //   "#32CD32",
      //   "#FFD700",
      //   "#FF1493",
      //   "#8A2BE2",
      //   "#00CED1",
      //   "#FF4500",
      //   "#7FFF00",
      //   "#DC143C",
      //   "#6495ED",
      //   "#FF8C00",
      //   "#00FA9A",
      //   "#8B4513",
      //   "#483D8B",
      //   "#00BFFF",
      //   "#FF6347",
      //   "#DA70D6",
      //   "#008080",
      //   "#ADFF2F",
      // ],
      // data series config
      series: seriesData,

      //exporting config
      exporting: {
        filename: nameForFile + "-" + new Date().toLocaleDateString("en-GB"),
        csv: {
          dateFormat: "%d/%m/%Y",
        },
        buttons: {
          contextButton: {
            menuItems: [
              "viewFullscreen",
              "separator",
              "downloadPNG",
              "downloadSVG",
              "downloadPDF",
              "separator",
              "downloadCSV",
              "downloadXLS",
              from === "Vanalytics"
                ? {
                    text: "View Table",
                    onclick: function () {
                      const chart = this;
                      let dataHtml = chart.getTable(); // Generate HTML table from chart data

                      // Replace empty cells with 0
                      dataHtml = dataHtml.replace(
                        /<td class="highcharts-empty">\s*<\/td>/g,
                        '<td class="highcharts-number">0</td>'
                      );
                      // Format timestamps in the table
                      dataHtml = dataHtml.replace(
                        /<th class="highcharts-text" scope="row">(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})<\/th>/g,
                        function (match, p1) {
                          const date = new Date(p1);
                          return `<th class="highcharts-text" scope="row">${date.toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "numeric",
                              year: "numeric",
                            }
                          )}</th>`;
                        }
                      );
                      setTableContent(dataHtml); // Store the table content in state
                      setShowTable(true); // Show the table
                    },
                  }
                : "",
            ],
          },
        },
        enabled: false,
        // showTable: true,
        columnHeaderFormatter: function (item, key) {
          if (item.isXAxis) {
            return item.options.title.text || item.name;
          }
          if (key === "y") {
            return item.yAxis.options.title.text || item.name;
          }
        },
      },

      // plot options
      plotOptions: {
        column: {
          stacking: isStacked ? "normal" : null,
        },
        series: {
          events: {
            legendItemClick: function () {
              const series = this.chart.series;
              const currentSeries = this;
              // console.log(series);

              if (series.length === 2) {
                const [series1, series2] = series;

                if (currentSeries === series1) {
                  // If series1 is clicked
                  if (series1.visible && !series2.visible) {
                    // Both series should be visible if series1 is visible and series2 is not
                    series1.update({ visible: true }, false);
                    series2.update({ visible: true }, false);
                  } else if (!series1.visible && series2.visible) {
                    // series1 should be visible and series2 should be hidden if series1 is not visible and series2 is visible
                    series1.update({ visible: true }, false);
                    series2.update({ visible: false }, false);
                  } else if (series1.visible && series2.visible) {
                    // If both are visible, make series2 invisible, and series1 remains visible
                    series1.update({ visible: true }, false);
                    series2.update({ visible: false }, false);
                  } else if (!series1.visible && !series2.visible) {
                    // If both are not visible, make both visible
                    series1.update({ visible: true }, false);
                    series2.update({ visible: true }, false);
                  }
                } else if (currentSeries === series2) {
                  // If series2 is clicked
                  if (series2.visible && !series1.visible) {
                    // Both series should be visible if series2 is visible and series1 is not
                    series1.update({ visible: true }, false);
                    series2.update({ visible: true }, false);
                  } else if (!series2.visible && series1.visible) {
                    // series2 should be visible and series1 should be hidden if series2 is not visible and series1 is visible
                    series1.update({ visible: false }, false);
                    series2.update({ visible: true }, false);
                  } else if (series1.visible && series2.visible) {
                    // If both are visible, make series1 invisible, and series2 remains visible
                    series1.update({ visible: false }, false);
                    series2.update({ visible: true }, false);
                  } else if (!series2.visible && !series1.visible) {
                    // If both are not visible, make both visible
                    series1.update({ visible: true }, false);
                    series2.update({ visible: true }, false);
                  }
                }

                // Finally, redraw the chart
                this.chart.redraw();
              } else {
                // Handle charts with more than two series or other cases
                this.chart.update(
                  {
                    series: series.map((s) => {
                      if (s === currentSeries) {
                        return {
                          visible: currentSeries.visible ? false : true,
                        };
                      } else {
                        const visbleSeries = series.filter(
                          (series) => series.visible
                        );
                        // console.log(
                        //   visbleSeries.length > 1 &&
                        //     visbleSeries.length < series.length - 1,
                        //   visbleSeries
                        // );

                        if (
                          visbleSeries.length > 1 &&
                          visbleSeries.length < series.length - 1
                        ) {
                          visbleSeries.forEach((series) => {
                            series.update({ visible: false }, false);
                          });
                        }
                        return {
                          visible: s.visible
                            ? visbleSeries.length > 1 &&
                              visbleSeries.length < series.length - 1
                              ? true
                              : false
                            : true,
                        };
                      }
                    }),
                  },
                  true
                );
              }

              return false; // Prevent the default action of hiding the clicked series
            },
          },
        },
      },

      //tooltip config
      tooltip: {
        useHTML: true,
        backgroundColor: isLight ? "white" : "#121212",
        formatter: function () {
          // console.log(this);
          const series = this.series;
          const point = this.point;
          if (chartName === "Daily Generation") {
            return `
            <div class="custom-tooltip" style="color:${"#1773A6"};">
            <b>${this.key}</b><br/>
              <div>
                <span style="color:${point.color}; font-size:20px;">■ </span>${
              series.name.split(" - ")[0]
            }: ${this.y} ${
              series.name.split(" - ")[1] === "Today Energy"
                ? "kWh"
                : series.name.split(" - ")[1] === "Irradiation"
                ? "Wh/m<sup>2"
                : ""
            }
              </div>
            </div>
          `;
          } else if (chartName === "Specific Yield") {
            return `
              <div class="custom-tooltip" style="color:${"#1773A6"};">
              <b>${this.key}</b><br/>
                <div style="margin-bottom: 2px; margin-top:2px;">
                  <b>${series.name.split(" - ")[1]}</b><br/>
                </div>
                <div>
                  <span style="color:${
                    point.color
                  }; font-size:20px;">■ </span>${
              series.name.split(" - ")[0]
            }: ${this.y} ${
              series.name.split(" - ")[1] === "Today Energy" ? "kWh" : ""
            }
                </div>
              </div>
            `;
          } else if (chartName === "Performance Ratio") {
            return `
              <div class="custom-tooltip" style="color:${"#1773A6"};">
              <b>${this.key}</b><br/>
                <div style="margin-bottom: 2px; margin-top:2px;">
                  <b>${series.name.split(" - ")[1]}</b><br/>
                </div>
                <div>
                  <span style="color:${
                    point.color
                  }; font-size:20px;">■ </span>${
              series.name.split(" - ")[0]
            }: ${this.y} %
                </div>
              </div>
            `;
          } else if (chartName === "Active Power") {
            return `
              <div class="custom-tooltip" style="color:${"#1773A6"};">
              <b>${moment(this.key).format("HH:mm")}</b><br/>
                <div style="margin-bottom: 2px; margin-top:2px;">
                  <b>${series.name
                    .split(" - ")[1]
                    .replace(/([a-z0-9])([A-Z])/g, "$1 $2")}</b><br/>
                </div>
                <div>
                  <span style="color:${
                    point.color
                  }; font-size:20px;">■ </span>${
              series.name.split(" - ")[0]
            }: ${this.y} kW</sup>
                </div>
              </div>
            `;
          }
        },
      },

      //legend config
      legend: {
        maxHeight: 50,
        itemStyle: {
          color: "#1773A6",
          fontSize: isDashboard ? "10px" : "14px",
        },
        itemHoverStyle: {
          color: isLight ? "gray" : "lightgray",
        },
      },

      //credits
      credits: {
        enabled: false,
      },
    };

    setChartOptions(chartOptions);
  }, [
    tableData,
    chartName,
    isStacked,
    isLight,
    isDashboard,
    WidgetName,
    from,
    nameForFile,
    mode,
  ]);

  console.log(chartOptions, "chartOptions");

  return (
    <div
      style={{ width: "100%", height: from === "Vanalytics" ? "500px" : null }}
    >
      {tableData?.length > 0 ? (
        showTable ? (
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                style={{
                  padding: "10px",
                  borderRadius: "10px",
                  height: "40px",
                  marginRight: "10px",
                }}
                variant="contained"
                size="small"
                onClick={() => setShowTable(false)}
              >
                <ArrowBackOutlinedIcon />
              </IconButton>
              <h2>{WidgetName?.split("-")[0]} Data Table</h2>
            </div>
            <div
              className="highcharts-data-table"
              dangerouslySetInnerHTML={{ __html: tableContent }}
            />
          </div>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            containerProps={{
              style: {
                width: "100%",
                height: "100%",
              },
            }}
            options={chartOptions}
          />
        )
      ) : (
        fallBack
      )}
    </div>
  );
};
