import axios from 'axios';
export const Equipmenttypedropdown = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_TYPE}`
      );
      return response.data;
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  };

  export const GetFileParamCoff = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_GET_ALL_PARAM_COFF}`
      );
      return response.data;
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  };