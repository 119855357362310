import React from "react";
import "./UserCon.css";
//--------------MUI----------------//
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Modal,
  Autocomplete,
  TextField,
  Grid,
  Card,
  CardContent,
  Backdrop,
  Divider,
  CircularProgress,
  Tooltip,
  Alert,
  CardHeader,
} from "@mui/material";
import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
//----------------API-----------------//
import {
  fetchUserTable,
  updateEditCall,
  postUserSave,
  Getdropdowndata,
  GetRoledropdowndata,
  GetCustomerdropdowndata,
  fetchUserTableById,
} from "../../Api/UserConfigurationAPI";
//------------ICON-------------//
import AdditionalFields from "../util/components/AdditionalFields";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import TableTemplate from "../../Template/TableTemplate";
import Download from "../../Template/Excel/Download";
import { useNavigate } from "react-router-dom";
import { userConfigurationField } from "../util/TextField";
import { userConfigSchema } from "../util/ValidateSchema";
import CustomSnackbar from "../util/components/CustomSnackbar";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  CountryDropdown,
  StateDropdown,
  Statusdropdown,
} from "../../Api/CommonApi";
import replaceObjectById from "../util/StateUpdater";
import errorMsg from "../util/errorMessage.json";
import { FadeLoader } from "react-spinners";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import { FetchUserType } from "../../Api/SignupApi";
import { useLocation } from "react-router-dom";
import { useAuth } from "../Auth/AuthContext";
import { CheckForLight } from "../util/common/CommanFunction";
import * as yup from "yup";
//--------------------MODAL------------------//
const stylemodal = {
  position: "absolute",
  top: "51%",
  left: "51%",
  transform: "translate(-50%, -50%)",
  width: "76%",
  height: "auto",
  bgcolor: "background.paper",
  p: 4,
};

const userStatusOptions = [
  { label: "Active", value: "1" },
  { label: "Inactive", value: "0" },
];

export default function UserConfigurat({ userConfiguration }) {
  const [open, setOpen] = React.useState(false);
  const { state, dispatch } = useAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const handleOpen = () => {
    setOpen(true);
    setEditValue();
    if (isOEM === true) {
      console.log("data");
    } else {
      setSnack({
        ...snack,
        open: true,
        severity: "warning",
        message:
          "All sites under the " +
          sessionStorage.getItem("customerName") +
          " " +
          "will be mapped to the " +
          sessionStorage.getItem("token"),
      });
    }
  };
  const handleClose = () => {
    setUserFields(initalState);
    setStateDrop([]);
    setEdit(false);
    setOpen(false);
  };

  window.addEventListener("message", (event) => {
    if (event.data === "closeWindow") {
      console.log(event.data, "recived");
    }
  });

  const navigate = useNavigate();
  const [Email, setEmail] = useState();
  const [loading, setloading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [tableValue, setTableValue] = useState([]);
  const [editValue, setEditValue] = useState();
  const [toBackend, setToBackend] = useState(false);
  const [edit, setEdit] = useState(false);
  const [customerId, setCustomerId] = useState([]);
  const [countryDrop, setCountryDrop] = useState([]);
  const [stateDrop, setStateDrop] = useState([]);
  const [roleId, setRoleId] = useState([]);
  const [showFields, setShowFields] = useState(false);
  const [siteTab, setSiteTab] = React.useState("");
  const [text, setTextField] = useState([]);
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const [addHeadCells, setAddHeadCells] = useState([]);
  const [customFieldsComponents, setCustomFieldsComponents] = useState([]);
  const [secMsg, setSecMsg] = useState(false);
  const isOEM = sessionStorage.getItem("userTypeId") == 1;
  console.log(isOEM);

  /*---------------------------Email Validation--------------------------------*/
  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setEmailError(!isValidEmail(newEmail));
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  useEffect(() => {
    let data = userConfigurationField();
    setTextField(data);
  }, []);

  const initalState = {
    city: "",
    userId: "",
    country: "",
    createdBy: "",
    emailId: "",
    firstName: "",
    lastName: "",
    mobileNo: "",
    postalCode: "",
    state: "",
    status: "",
    lastUpdatedBy: "",
    roleName: "",
    customerId: "",
    companyName: "",
    customerName: "",
    designation: "",
    userTypeName: "",
    encryptedPassword: "",
    useradditionalfield: [],
  };

  const [userFields, setUserFields] = useState(initalState);
  const [statusDrop, setStatusDrop] = useState([]);
  const [userTypeList, setUserType] = useState([]);
  console.log(userFields.customerId, userFields.customerName);

  const handleNavigate = () => {
    console.log("Navigating to /config/admin/multiSite");
    navigate("/config/admin/multiSite");
  };

  useEffect(() => {
    fetchData();
    getRoleList();
    getCustomerList();
    getCountryList();
    userType();
    fetchStatus();
  }, []);

  //----------------User Table----------------//

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const params = {};
  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }
  const fetchData = async () => {
    setloading(true);
    try {
      const result = await fetchUserTableById();
      let data = [];
      if (
        sessionStorage.getItem("roleName") !== "Super Admin" ||
        sessionStorage.getItem("roleName") === "Super Admin-Hummer"
      ) {
        data = result.filter(
          (data) =>
            data.roleName !== "Super Admin" &&
            data.roleName !== "Super Admin-Hummer"
        );
      } else {
        data = result;
      }
      data?.forEach((obj) => {
        obj?.useradditionalfield.forEach((element) => {
          const fieldExists = data.some((item) =>
            item.hasOwnProperty(element.fieldName)
          );
          if (!fieldExists) {
            data.forEach((item) => (item[element.fieldName] = "-"));
          }
          obj[element.fieldName] = element.value || "-";
        });
      });

      let tableData = data;
      if (Array.isArray(data)) {
        tableData = data;
        setTableValue(tableData);
      } else if (typeof data === "object" && data !== null) {
        tableData = [data];
        setTableValue(tableData);
      }

      if (Object.keys(params).length > 0) {
        const userId = params.key;
        const filteredData = tableData.filter((item) => item.userId == userId);
        if (filteredData.length > 0) {
          setUserFields(filteredData[0]);
          setEditValue(filteredData[0]);
          setEdit(true);
          setOpen(true);
        }
      }
      setloading(false);
    } catch (e) {
      console.log(e);
      setloading(false);
    }
  };
  useEffect(() => {
    if (userFields.country !== "" && userFields.country !== null) {
      let countryID = countryDrop?.filter((data) => {
        return data.countryName === userFields.country;
      });
      getStateList(countryID[0]?.countryId);
    }
  }, [userFields.country]);

  const getStateList = async (Id) => {
    try {
      let data = await StateDropdown(Id);
      setStateDrop(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getCountryList = async () => {
    try {
      let data = await CountryDropdown();
      setCountryDrop(data);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === "object" && data !== null) {
      setStatusDrop([data]);
    }
  };
  //----------------User Company List Get Api----------------//
  const getRoleList = async () => {
    function removeObjectById(array) {
      return array.filter((obj) => {
        const roleName = sessionStorage.getItem("roleName");
        if (roleName !== "Super Admin" && roleName !== "Super Admin-Hummer") {
          // For non-super admin roles, exclude objects by `id` and exclude `Super Admin` and `Super Admin-Hummer`
          return (
            obj.roleName !== "Super Admin" &&
            obj.roleName !== "Super Admin-Hummer"
          );
        } else {
          return obj;
        }
      });
    }

    try {
      let data = await GetRoledropdowndata();
      console.log(data, "role");
      setRoleId(removeObjectById(data));
    } catch (e) {
      console.error(e);
    }
  };

  const getCustomerList = async () => {
    try {
      let data = await GetCustomerdropdowndata();
      console.log(data, "GetCustomerdropdowndata");
      if (Array.isArray(data)) {
        setCustomerId(data);
      } else if (typeof data === "object" && data !== null) {
        setCustomerId([data]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const userType = async () => {
    let responseData = await FetchUserType();
    console.log(responseData.data, "responseData");
    setUserType(responseData.data);
  };

  const buttonStyles = {
    textTransform: "capitalize",
    boxShadow: "4px 4px 17px rgba(0, 0, 0.50, 0.50)",
    width: "7vw",
    fontSize: "1.1rem",
    border: "none",
  };

  const allowedKeys = ["fieldName", "value", "status"];

  const addAdditionalField = () => {
    const newField = {
      fieldName: "",
      status: 1,
      value: "",
    };
    setUserFields((prevState) => ({
      ...prevState,
      useradditionalfield: [...prevState.useradditionalfield, newField],
    }));
  };

  const deleteAdditionalField = (index) => {
    const updatedFields = [...userFields.useradditionalfield];
    updatedFields.splice(index, 1);
    setUserFields((prevState) => ({
      ...prevState,
      useradditionalfield: updatedFields,
    }));
  };
  const handleDropdownChange = (event, value, name) => {
    console.log(value, name);

    // if (isOEM === true && name==="customerName") {
    //   setSnack({
    //     ...snack,
    //     open: true,
    //     severity: 'warning',
    //     message: "All sites under the "+value+" "+"will be mapped to the "+sessionStorage.getItem('token'),
    //   });
    // }
    if (name === "country") {
      setUserFields({ ...userFields, state: null, [name]: value });
    } else {
      setUserFields({ ...userFields, [name]: value });
    }
    console.log(userFields.customerName);
  };
  console.log(userFields.userTypeName);
  const handleSubmit = async (values) => {
    // let customerIdFilter = customerId?.filter((data) => {
    //   return data.companyName === userFields.companyName;
    // });
    console.log(userFields, "hdfhhsdlghlksdjlkdlkjlkk");
    let roleIdFilter = roleId?.filter((data) => {
      return data.roleName === userFields.roleName;
    });

    let customerIdFilter = customerId?.filter((data) => {
      return data.customerName === userFields.customerName;
    });

    let userTypeId = userTypeList?.filter(
      (data) => data.userTypeName === userFields.userTypeName
    );
    // console.log(roleIdFilter);
    let userAddField = [];
    userFields.useradditionalfield.forEach((obj) => {
      if (obj.fieldName && obj.value) {
        userAddField.push({
          createdBy: Number(sessionStorage.getItem("id")),
          fieldName: obj.fieldName,
          status: Number(obj.status),
          value: obj.value,
        });
      }
    });
    console.log(values, "values");
    let data =
      isOEM && customerIdFilter[0]?.customerId !== undefined
        ? {
            city: values?.city,
            country: values?.country,
            createdBy: Number(sessionStorage.getItem("id")),
            lastUpdatedBy: Number(sessionStorage.getItem("id")),
            emailId: values?.emailId,
            firstName: values?.firstName,
            lastName: values?.lastName,
            mobileNo: values?.mobileNo,
            postalCode: values?.postalCode,
            state: values?.state,
            status: 1,
            userTypeId: isOEM
              ? userTypeId[0].userTypeId
              : Number(sessionStorage.getItem("userTypeId")),
            roleId: Number(roleIdFilter[0]?.roleId),
            companyId: sessionStorage.getItem("companyId"),
            customerId: Number(customerIdFilter[0].customerId) || undefined,
            designation: values.designation,
            useradditionalfield: userAddField,
          }
        : isOEM && customerIdFilter[0]?.customerId === undefined
        ? {
            city: values?.city,
            country: values?.country,
            createdBy: Number(sessionStorage.getItem("id")),
            lastUpdatedBy: Number(sessionStorage.getItem("id")),
            emailId: values?.emailId,
            firstName: values?.firstName,
            lastName: values?.lastName,
            mobileNo: values?.mobileNo,
            postalCode: values?.postalCode,
            state: values?.state,
            status: 1,
            userTypeId: isOEM
              ? userTypeId[0].userTypeId
              : Number(sessionStorage.getItem("userTypeId")),
            roleId: Number(roleIdFilter[0]?.roleId),
            companyId: sessionStorage.getItem("companyId"),
            designation: values.designation,
            useradditionalfield: userAddField,
          }
        : {
            city: values?.city,
            country: values?.country,
            createdBy: Number(sessionStorage.getItem("id")),
            lastUpdatedBy: Number(sessionStorage.getItem("id")),
            emailId: values?.emailId,
            firstName: values?.firstName,
            lastName: values?.lastName,
            mobileNo: values?.mobileNo,
            postalCode: values?.postalCode,
            state: values?.state,
            status: 1,
            userTypeId: isOEM
              ? userTypeId[0].userTypeId
              : Number(sessionStorage.getItem("userTypeId")),
            roleId: Number(roleIdFilter[0]?.roleId),
            companyId: sessionStorage.getItem("companyId"),
            customerId: Number(sessionStorage.getItem("customerId")),
            designation: values.designation,
            useradditionalfield: userAddField,
          };
    console.log(data, "dataSubmit");
    let responseData;
    try {
      setToBackend(true);
      responseData = await postUserSave(data);
      setTableValue([...tableValue, values]);
      fetchData();
      console.log(data, "data");
      setSecMsg(true);
      setSnack({
        ...snack,
        open: true,
        severity: "success",
        message: responseData.statusDescription,
      });
      await delay(2000);
      setSecMsg(false);
      setSnack(errorMsg.firstLogin);
      handleClose();
      setToBackend(false);
      await delay(3000);
      navigate("/config/admin/multiSite", { replace: true });
      console.log("Response:", responseData);
      return 1;
    } catch (error) {
      if (error.response.status === 406) {
        console.log(error.response.data.statusDescription);
        setSnack({
          open: true,
          severity: "error",
          message: error.response.data.statusDescription,
        });
      } else {
        setSnack(errorMsg.failure);
      }
      console.error("Error:", error.response.status);
      setToBackend(false);
      return 0;
    }
  };
  /*-------------------------Edit Icon----------------------*/
  const handleEditClick = async (userId) => {
    let filtereduserId = Object.keys(initalState).reduce((acc, key) => {
      if (userId.hasOwnProperty(key)) {
        acc[key] = userId[key];
      }
      return acc;
    }, {});
    setUserFields(filtereduserId);
    setEditValue(userId);
    setEdit(true);
    setOpen(true);
  };

  function getUserTypeId(userType) {
    return userTypeList?.filter((data) => data.userTypeName === userType);
  }

  /*----------------------------- update call -------------------------------*/
  const handleUpdate = async (values) => {
    console.log(values, "values");
    let cty = values.city !== "" ? values.city : editValue.city;
    let cntry = values.country !== "" ? values.country : editValue.country;
    let emailId = values.emailId !== "" ? values.emailId : editValue.emailId;
    let frstname =
      values.firstName !== "" ? values.firstName : editValue.firstName;
    let lstname = values.lastName !== "" ? values.lastName : editValue.lastName;
    let mobileNo =
      values.mobileNo !== "" ? values.mobileNo : editValue.mobileNo;
    let pstlcode =
      values.postalCode !== "" ? values.postalCode : editValue.postalCode;
    let ste = values.state !== "" ? values.state : editValue.state;
    let userstatus = userFields.status
      ? userFields.status === "Active"
        ? 1
        : 0
      : editValue.status;
    let desig =
      values.designation !== "" ? values.designation : editValue.designation;
    values.firstName = frstname;
    let status = statusDrop?.filter((data) => {
      return data.label === values.status;
    });
    let roleIdFilter = roleId?.filter((data) => {
      return data.roleName === values.roleName;
    });
    console.log(roleIdFilter, "roleIdFiltr", roleId);
    let customerIdFilter = customerId?.filter((data) => {
      return data.customerId === values.customerId;
    });
    let customerIdFilterr = customerId?.filter((data) => {
      return data.customerName === userFields.customerName;
    });

    let userAddField = [];
    userFields.useradditionalfield.forEach((obj) => {
      if (obj.fieldName && obj.value) {
        if (obj.fieldId) {
          userAddField.push({
            lastUpdatedBy: sessionStorage.getItem("id"),
            fieldId: obj.fieldId,
            fieldName: obj.fieldName,
            status: Number(obj.status),
            value: obj.value,
          });
        } else {
          userAddField.push({
            createdBy: Number(sessionStorage.getItem("id")),
            fieldName: obj.fieldName,
            status: Number(obj.status),
            value: obj.value,
          });
        }
      }
    });

    let userTypeId = userTypeList?.filter(
      (data) => data.userTypeName === userFields.userTypeName
    );
    let obj =
      isOEM && customerIdFilter[0]?.customerId !== undefined
        ? {
            city: cty,
            country: cntry,
            emailId: emailId,
            firstName: frstname,
            lastName: lstname,
            mobileNo: mobileNo,
            postalCode: Number(pstlcode),
            state: ste,
            userTypeId: isOEM
              ? userTypeId[0].userTypeId
              : Number(sessionStorage.getItem("userTypeId")),
            status: edit
              ? typeof values.status === "string"
                ? status[0].value
                : values.status
              : status[0].value,
            lastUpdatedBy: Number(sessionStorage.getItem("id")),
            designation: desig,
            roleId:
              roleIdFilter.length === 0
                ? editValue.roleId
                : roleIdFilter[0].roleId,
            companyId: sessionStorage.getItem("companyId"),
            customerId: Number(customerIdFilter[0].customerId) || undefined,
            useradditionalfield: userAddField,
          }
        : isOEM && customerIdFilter[0]?.customerId === undefined
        ? {
            city: cty,
            country: cntry,
            emailId: emailId,
            firstName: frstname,
            lastName: lstname,
            mobileNo: mobileNo,
            postalCode: Number(pstlcode),
            state: ste,
            userTypeId: isOEM
              ? userTypeId[0].userTypeId
              : Number(sessionStorage.getItem("userTypeId")),
            status: edit
              ? typeof values.status === "string"
                ? status[0].value
                : values.status
              : status[0].value,
            lastUpdatedBy: Number(sessionStorage.getItem("id")),
            designation: desig,
            roleId:
              roleIdFilter.length === 0
                ? editValue.roleId
                : roleIdFilter[0].roleId,
            companyId: sessionStorage.getItem("companyId"),
            useradditionalfield: userAddField,
            customerId: Number(customerIdFilterr[0]?.customerId) || undefined,
          }
        : {
            city: cty,
            country: cntry,
            emailId: emailId,
            firstName: frstname,
            lastName: lstname,
            mobileNo: mobileNo,
            postalCode: Number(pstlcode),
            state: ste,
            userTypeId: isOEM
              ? userTypeId[0].userTypeId
              : Number(sessionStorage.getItem("userTypeId")),
            status: edit
              ? typeof values.status === "string"
                ? status[0].value
                : values.status
              : status[0].value,
            lastUpdatedBy: Number(sessionStorage.getItem("id")),
            designation: desig,
            roleId:
              roleIdFilter.length === 0
                ? editValue.roleId
                : roleIdFilter[0].roleId,
            companyId: sessionStorage.getItem("companyId"),
            customerId: Number(sessionStorage.getItem("customerId")),
            useradditionalfield: userAddField,
          };
    console.log(
      obj,
      "data to update",
      isOEM && customerIdFilter[0]?.customerId !== undefined,
      customerIdFilter[0]
    );
    try {
      setToBackend(true);
      let response = await updateEditCall(obj, editValue?.userId);
      const updatedTable = replaceObjectById(
        tableValue,
        "userId",
        editValue?.userId,
        response.data
      );
      console.log("200 is working");
      fetchData();
      setSnack(errorMsg.success);
      if (Object.keys(params).length == 0) {
        handleClose();
      } else {
        window.close();
      }
      setToBackend(false);
      return 1;
    } catch (error) {
      if (error.response.status === 406) {
        setSnack(errorMsg.unique.userMail);
      } else {
        setSnack(errorMsg.failure);
      }

      setToBackend(false);
      return 0;
    }
  };

  // console.log(editValue, 'editValue');
  /*----------------------- On change function for Add / Edit ----------------------*/

  const handleTxtChange = (event) => {
    console.log(event.target.name, event.target.value);
    setUserFields({ ...userFields, [event.target.name]: event.target.value });
  };

  const handleDownloadTable = async (val) => {
    const status = await Download(val, "User List");
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };

  const headCells = [
    {
      label: "First Name",
      id: "firstName",
      view: true,
      default: true,
    },
    {
      label: "Email Id",
      id: "emailId",
      view: true,
      default: true,
    },
    // {
    //   label: 'Organization',
    //   id: 'organization',
    //   view: true,
    // },
    {
      label: "City",
      id: "city",
      view: true,
    },
    {
      label: "Mobile No",
      id: "mobileNo",
      view: true,
    },
    {
      label: "Country",
      id: "country",
      view: false,
    },
    {
      label: "State",
      id: "state",
      view: false,
    },
    {
      label: "Designation",
      id: "designation",
      view: true,
    },
    {
      label: "Role",
      id: "roleName",
      view: false,
    },
    {
      label: "User Type",
      id: "userTypeName",
      view: true,
    },
    {
      label: "Customer Name",
      id: "customerName",
      view: false,
      default: false,
    },
    {
      label: "Status",
      id: "status",
      view: true,
    },
    {
      label: "Action",
      id: "action",
      view: true,
      default: true,
    },
  ];

  useEffect(() => {
    setloading(true);
    let viewArr = [...headCells];
    tableValue.forEach((obj) => {
      const addField = obj.useradditionalfield;
      console.log(addField, "addField");
      if (addField?.length > 0) {
        addField.forEach((field) => {
          let key = field.fieldName;
          if (!viewArr.some((item) => item.id === key)) {
            let lastIndex = viewArr.length - 1;
            viewArr.splice(lastIndex, 0, { id: key, label: key });
          }
          setloading(false);
        });
      } else {
        setloading(false);
      }
    });
    setAddHeadCells(viewArr);
  }, [headCells.length, addHeadCells.length, tableValue]);

  const tableValues = tableValue.filter((row) => {
    if (siteTab !== "") {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case "all":
        setSiteTab("");
        break;
      case "Active":
        setSiteTab(1);
        break;
      case "Inactive":
        setSiteTab(0);
        break;
      default:
        setSiteTab("");
    }
  };
  const Total = tableValue.length;

  const Active = tableValue.filter((site) => site.status == 1).length;

  const Inactive = tableValue.filter((site) => site.status == 0).length;
  // console.log(Active);
  const tabsData = [
    { name: "All User", value: "all", badgeCount: Total },
    { name: "Active User", value: "Active", badgeCount: Active },
    {
      name: "Inactive User",
      value: "Inactive",
      badgeCount: Inactive,
    },
  ];

  const AddFieldsLength = userFields.useradditionalfield.length;

  const oemUserENDSchema = yup.object().shape({
    userTypeName: yup.string().required("User Type is required"),
  });
  const oemUserOEMSAddedSchema = yup.object().shape({
    userTypeName: yup.string().required("User Type is required"),
  });
  let preferedScema;
  if (isOEM) {
    if (userFields.userTypeName === "OEM") {
      preferedScema = userConfigSchema.concat(oemUserOEMSAddedSchema);
    } else {
      preferedScema = userConfigSchema.concat(oemUserENDSchema);
    }
  } else {
    preferedScema = userConfigSchema;
  }

  const handleSnackClose = () => {
    setSnack(initialSnack);
  };

  const paths = [
    { label: "Admin", path: "/config/admin/userconf" },
    { label: "User Configuration", path: "admin/userconf" },
  ];
  const headerContent = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div className="modelTypo">
        <Typography
          id="transition-modal-title"
          variant="h6"
          component="h4"
          className="modal-head"
          style={{ fontWeight: "bold" }}
        >
          {edit ? "Update User" : "Add New User"}
        </Typography>
      </div>
      {userFields.roleName === "Admin" ||
      userFields.roleName === "Super Admin" ? (
        <Alert
          severity="error"
          style={{
            userSelect: "none",
            height: "40px",
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {`The user has been given ${userFields.roleName} access.`}
        </Alert>
      ) : null}
    </div>
  );
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <>
          <Formik
            key={edit ? "edit" : "add"}
            enableReinitialize={true}
            initialValues={userFields}
            validationSchema={preferedScema}
            onSubmit={async (values, { resetForm }) => {
              let submitValue = values;
              if (edit) {
                const result = handleUpdate(submitValue);
                if (result === 1) {
                  resetForm();
                }
              } else {
                const result = await handleSubmit(submitValue);
                if (result === 1) {
                  resetForm();
                }
              }
            }}
          >
            {({ values, handleChange, handleSubmit, handleReset }) => (
              <Form>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                >
                  <Fade in={open}>
                    <Box
                      className={"styleModal"}
                      // sx={{ bgcolor: 'background.paper' }}
                    >
                      <Card
                        sx={{
                          borderRadius: "20px",
                          // height: '68vh',
                          height: "fit-content",
                        }}
                      >
                        <CardHeader
                          sx={{
                            padding: "20px 20px 20px 20px",
                            background: CheckForLight()
                              ? "rgb(246, 248, 252)"
                              : "#4f4f4f",
                          }}
                          action={
                            <Stack spacing={2} direction="row">
                              {edit ? (
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  startIcon={
                                    toBackend ? (
                                      <CircularProgress
                                        size={20}
                                        color="inherit"
                                      />
                                    ) : (
                                      <SaveIcon />
                                    )
                                  }
                                  onClick={toBackend ? null : handleSubmit}
                                >
                                  {toBackend ? "Updating..." : "Update"}
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  startIcon={
                                    toBackend ? (
                                      <CircularProgress
                                        size={20}
                                        color="inherit"
                                      />
                                    ) : (
                                      <SaveIcon />
                                    )
                                  }
                                  onClick={toBackend ? null : handleSubmit}
                                >
                                  {toBackend ? "Saving..." : "Save"}
                                </Button>
                              )}
                              <Button
                                variant="contained"
                                disabled={toBackend}
                                style={{
                                  borderRadius: "25px",
                                  textTransform: "capitalize",
                                }}
                                onClick={() => {
                                  handleClose();
                                  handleReset();
                                }}
                                startIcon={<CancelIcon />}
                              >
                                Cancel
                              </Button>
                            </Stack>
                          }
                          title={headerContent}
                        />
                        <Divider sx={{ borderColor: "#888" }} />
                        <Card
                          sx={{
                            overflowY:
                              AddFieldsLength !== 0 ? "scroll" : "visible",
                            // height: '26rem',
                            scrollBehavior: "smooth",
                            scrollbarGutter: "stable",
                            scrollbarWidth: "thin",
                            "&::-webkit-scrollbar": {
                              width: "0.4em",
                            },
                            "&::-webkit-scrollbar-track": {
                              background: "#f1f1f1",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#888",
                              borderRadius: "20px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                              background: "#555",
                            },
                          }}
                          elevation={0}
                        >
                          <CardContent sx={{ pt: 0 }}>
                            <>
                              <div className="modelGrid">
                                <Grid container spacing={2} columns={16}>
                                  {text?.length > 0
                                    ? text.map((data, index) => {
                                        return data.view ? (
                                          <Grid item xs={4}>
                                            <div key={index}>
                                              <Typography
                                                variant="body1"
                                                className="modal-typo"
                                                gutterBottom
                                              >
                                                {data.label}
                                              </Typography>
                                              {data.type === "textField" ? (
                                                <>
                                                  <Field
                                                    as={TextField}
                                                    id={`outlined-basic-${index}`}
                                                    size="small"
                                                    variant="outlined"
                                                    inputProps={{
                                                      maxLength: data.length,
                                                    }}
                                                    name={data.name}
                                                    disabled={toBackend}
                                                    onChange={(e) => {
                                                      handleChange(e);
                                                      handleTxtChange(e);
                                                    }}
                                                    placeholder={
                                                      data.placeholder
                                                    }
                                                    value={
                                                      values[data.name] === null
                                                        ? ""
                                                        : values[data.name]
                                                    }
                                                    sx={{ width: "12vw" }}
                                                  />
                                                  <ErrorMessage
                                                    name={data.name}
                                                    component="div"
                                                    className="errorStyle"
                                                    style={{
                                                      color: "red",
                                                      marginTop: "1%",
                                                      textAlign: "left",
                                                      marginLeft: "0%",
                                                    }}
                                                  />
                                                </>
                                              ) : data.type === "number" ? (
                                                <>
                                                  <Field
                                                    as={TextField}
                                                    id={`outlined-basic-${index}`}
                                                    size="small"
                                                    disabled={toBackend}
                                                    variant="outlined"
                                                    name={data.name}
                                                    type={data.type}
                                                    placeholder={
                                                      data.placeholder
                                                    }
                                                    onChange={(e) => {
                                                      console.log(
                                                        e.target.value.length,
                                                        "eeeeeeee"
                                                      );
                                                      let numLength;
                                                      let inputValue =
                                                        e.target.value;
                                                      const onlyDigits =
                                                        /^\d+$/;

                                                      if (
                                                        e.target.name ===
                                                        "mobileNo"
                                                      ) {
                                                        numLength = 10;
                                                      } else if (
                                                        e.target.name ===
                                                        "postalCode"
                                                      ) {
                                                        numLength = 6;
                                                      }
                                                      if (
                                                        onlyDigits.test(
                                                          inputValue
                                                        ) &&
                                                        inputValue.length <=
                                                          numLength
                                                      ) {
                                                        handleChange(e);
                                                        handleTxtChange(e);
                                                      } else if (
                                                        inputValue === ""
                                                      ) {
                                                        // Reset the state for the field if input value is empty
                                                        setUserFields({
                                                          ...userFields,
                                                          [e.target.name]: "",
                                                        });
                                                      }
                                                    }}
                                                    value={
                                                      values[data.name] === null
                                                        ? ""
                                                        : values[data.name]
                                                    }
                                                    sx={{ width: "12vw" }}
                                                  />
                                                  <ErrorMessage
                                                    name={data.name}
                                                    component="div"
                                                    className="errorStyle"
                                                    style={{
                                                      color: "red",
                                                      marginTop: "1%",
                                                      textAlign: "left",
                                                      marginLeft: "0%",
                                                    }}
                                                  />
                                                </>
                                              ) : data.type === "dropdown" &&
                                                data.view ? (
                                                <>
                                                  <Tooltip
                                                    title={
                                                      !edit &&
                                                      data.name === "status"
                                                        ? "Active"
                                                        : edit &&
                                                          data.name === "status"
                                                        ? typeof values[
                                                            data.name
                                                          ] === "string"
                                                          ? values[data.name]
                                                          : values[data.name] ==
                                                            1
                                                          ? "Active"
                                                          : "Inactive"
                                                        : values[data.name]
                                                    }
                                                  >
                                                    <Field
                                                      name={data.name} // Ensure this matches the field name in your validation schema
                                                      render={({
                                                        field,
                                                        form,
                                                      }) => (
                                                        <Tooltip
                                                          title={
                                                            !edit &&
                                                            data.name ===
                                                              "status"
                                                              ? "Active"
                                                              : edit &&
                                                                data.name ===
                                                                  "status"
                                                              ? typeof values[
                                                                  data.name
                                                                ] === "string"
                                                                ? values[
                                                                    data.name
                                                                  ]
                                                                : values[
                                                                    data.name
                                                                  ] == 1
                                                                ? "Active"
                                                                : "Inactive"
                                                              : values[
                                                                  data.name
                                                                ]
                                                          }
                                                        >
                                                          <Autocomplete
                                                            disableClearable
                                                            disabled={
                                                              toBackend ||
                                                              (!edit &&
                                                                data.name ===
                                                                  "status") ||
                                                              (!edit &&
                                                                data.name ===
                                                                  "customerName" &&
                                                                userFields.userTypeName ===
                                                                  "OEM") ||
                                                              (edit &&
                                                                data.name ===
                                                                  "customerName" &&
                                                                userFields.userTypeName ===
                                                                  "OEM")
                                                            }
                                                            autoHighlight
                                                            options={
                                                              data.name ===
                                                              "roleName"
                                                                ? roleId.map(
                                                                    (option) =>
                                                                      option.roleName
                                                                  )
                                                                : data.name ===
                                                                  "customerName"
                                                                ? customerId.map(
                                                                    (
                                                                      option
                                                                    ) => {
                                                                      return `${option.customerName}`;
                                                                    }
                                                                  )
                                                                : data.name ===
                                                                  "country"
                                                                ? countryDrop.map(
                                                                    (option) =>
                                                                      option.countryName
                                                                  )
                                                                : data.name ===
                                                                  "state"
                                                                ? stateDrop.map(
                                                                    (option) =>
                                                                      option.stateName
                                                                  )
                                                                : data.name ===
                                                                  "status"
                                                                ? statusDrop.map(
                                                                    (option) =>
                                                                      option.label
                                                                  )
                                                                : data.name ===
                                                                  "userTypeName"
                                                                ? userTypeList?.map(
                                                                    (option) =>
                                                                      option.userTypeName
                                                                  )
                                                                : null
                                                            }
                                                            disablePortal
                                                            size="small"
                                                            id={`combo-box-demo-${index}`}
                                                            onChange={(
                                                              event,
                                                              value
                                                            ) =>
                                                              handleDropdownChange(
                                                                event,
                                                                value,
                                                                data.name
                                                              )
                                                            }
                                                            placeholder={
                                                              data.placeholder
                                                            }
                                                            value={
                                                              !edit &&
                                                              data.name ===
                                                                "status"
                                                                ? "Active"
                                                                : edit &&
                                                                  data.name ===
                                                                    "status"
                                                                ? typeof values[
                                                                    data.name
                                                                  ] === "string"
                                                                  ? values[
                                                                      data.name
                                                                    ]
                                                                  : values[
                                                                      data.name
                                                                    ] == 1
                                                                  ? "Active"
                                                                  : "Inactive"
                                                                : values[
                                                                    data.name
                                                                  ]
                                                            } // Ensure value is controlled by Formik
                                                            sx={{
                                                              width: "12vw",
                                                            }}
                                                            renderInput={(
                                                              params
                                                            ) => (
                                                              <TextField
                                                                {...params}
                                                                placeholder={
                                                                  data.placeholder
                                                                }
                                                              />
                                                            )}
                                                          />
                                                        </Tooltip>
                                                      )}
                                                    />
                                                  </Tooltip>
                                                  <ErrorMessage
                                                    name={data.name}
                                                    component="div"
                                                    className="errorStyle"
                                                    style={{
                                                      color: "red",
                                                      marginTop: "1%",
                                                      textAlign: "left",
                                                      marginLeft: "0%",
                                                    }}
                                                  />
                                                </>
                                              ) : null}
                                            </div>
                                          </Grid>
                                        ) : null;
                                      })
                                    : null}
                                  <Grid item xs={4}>
                                    <div style={{ textAlign: "center" }}></div>
                                  </Grid>
                                </Grid>
                              </div>
                              {AddFieldsLength !== 0 && (
                                <>
                                  <Typography
                                    className="modal-head"
                                    style={{ width: "300px" }}
                                    sx={{
                                      marginLeft: "3%",
                                      marginTop: "18px",
                                    }}
                                    gutterBottom
                                  >
                                    Additional Fields
                                  </Typography>
                                  <Grid container spacing={3} columns={30}>
                                    <Grid
                                      item
                                      xs={8}
                                      sx={{
                                        marginLeft: "3%",
                                        marginTop: "3px",
                                      }}
                                    >
                                      <div>
                                        <AdditionalFields
                                          field={userFields}
                                          setField={setUserFields}
                                          allowedKeys={allowedKeys}
                                          deleteAdditionalField={
                                            deleteAdditionalField
                                          }
                                          fieldToAdd="useradditionalfield"
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                              <Grid item xs={8} sx={{ marginTop: "20px" }}>
                                {userFields.useradditionalfield.length < 5 && (
                                  <Card
                                    sx={{
                                      width: "88%",
                                      marginLeft: "4%",
                                      height: "45px",
                                      border: "2px solid #d0d0d0",
                                      borderStyle: "dashed",
                                      borderRadius: "10px",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    elevation={0}
                                  >
                                    <CardContent
                                      onClick={addAdditionalField}
                                      sx={{
                                        cursor: "pointer",
                                        display: "flex",
                                        marginTop: "0.5%",
                                        alignItems: "center",
                                      }}
                                    >
                                      <AddCircleOutlineSharpIcon
                                        sx={{
                                          color: "#71766f",
                                          marginRight: "10px",
                                          fontSize: "30px",
                                        }}
                                      />
                                      <Typography
                                        className="modal-typo"
                                        fontWeight="410"
                                        fontSize="20px !important"
                                      >
                                        Add customized data
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                )}
                              </Grid>
                            </>
                          </CardContent>
                        </Card>
                      </Card>
                    </Box>
                  </Fade>
                </Modal>
              </Form>
            )}
          </Formik>
          <div>
            <CustomSnackbar
              open={snack.open}
              onClose={handleSnackClose}
              autoHideDuration={secMsg ? 2000 : 5000}
              severity={snack.severity}
              message={snack.message}
            />
            <TableTemplate
              PageName={"User Configuration"}
              addButton={"User"}
              SearchLabel={"Search User Here... "}
              header={addHeadCells}
              rowsValue={tableValues}
              tabsData={tabsData}
              handleChange={handleChangeTab}
              rawData={tableValue}
              handleAddPopupOpen={(val) => handleOpen(val)}
              handleEditPopupOpen={(val) => handleEditClick(val)}
              userRole={userConfiguration[0]}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              paths={paths}
              fallbackText={`No${
                siteTab === 1 ? " Active" : siteTab === 0 ? " Inactive" : ""
              } User has been created yet`}
            />
          </div>
        </>
      )}
    </div>
  );
}
