import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  Autocomplete,
  TextField,
  Stack,
  Divider,
  Modal,
  CardHeader,
  Card,
  CardContent,
  IconButton,
  TableCell,
  Tooltip,
  TableBody,
  TableRow,
  CircularProgress,
  TableContainer,
  TableHead,
  Table,
  Backdrop,
  Fade,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { ErrorMessage, Field, Form, Formik } from "formik";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { MasterTypeSchema } from "../../util/ValidateSchema";
import {
  Equipmenttypedropdown,
  GetFileParamCoff,
} from "../../../Api/ParameterApi";
import TableTemplate from "../../../Template/TableTemplate";
import CustomBreadcrumbs from "../../util/components/CustomBread";
import CustomSnackbar from "../../util/components/CustomSnackbar";
import { TabsComponent } from "../../util/components/Tabs";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import FadeLoader from "react-spinners/FadeLoader";
export default function Ftpgetfile({ Report }) {
  const initialState = {
    equipmentName: "",
    dataloggerName: "",
    configfile: "",
    parameterName: "Dummy",
    Status: "",
    masterNamedata: "",
    filepath: "",
    coeffient:"",
  };
  const [stdField, setstdField] = useState(initialState);
  console.log(stdField);
  const [internalLoader, setInternalLoader] = useState(false);
  const [equipmentName, setequipmentId] = useState([]);
  const [rowValues, setRowValues] = useState({});
  console.log(rowValues);
  
  const [editlog, seteditlog] = useState(false);
  const [openMaster, setOpenMaster] = useState(false);
  const [ParameterName, setParameterName] = useState([]);
  const initialSnack = { open: false, severity: "error", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const [toBackend, setToBackend] = useState(false);
  const [value, setValue] = React.useState(0);
  const [editValue, setEditvalue] = useState(null);
  const [Openmadal, SetOpenmadal] = useState(false);
  const [dataLoggerValue, setDataloggerValue] = useState("");
  const [tablevalue, setTablevalue] = useState();
  const [tablemap, setTablemap] = useState();
  const [File, setFile] = useState([]);
    const [site, setSite] = React.useState("");
  console.log(File);

  console.log(snack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Parameter ", path: "ftppget" },
  ];
  const Item = styled(Paper)(({ theme }) => ({}));
  /*------------------------- handle save --------------------------------------------*/
  const handleSave = async () => {
    try {
      setToBackend(true);
      //   const responseData = await fetchcurring(fromDate, toDate, siteId);
      const responseData = "";
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        severity: "error",
        message: "An error occurred. Please try again.",
      });
      console.error("Error:", error);
    }
    setToBackend(false);
  };

  useEffect(() => {
    getSiteCall();
    fetchdata();
    fetchstandardParameter();
  }, []);
  const dummy = [
    "Total Active Energy Export - ",
    "Total Active Power Meter - ",
    "R-N Phase Voltage - ",
    "Y-N Phase Voltage - ",
    "B-N Phase Voltage - ",
    "R-Y Phase Voltage - ",
    "Y-B Phase Voltage - ",
    "B-R Phase Voltage - ",
    "Avg Power Factor - ",
    "R Current - ",
    "Y Current - ",
    "B Current - ",
  ];
  const config = [
    {
      data: "SMA_16KW.csv",
      data: "WPM015D9E_IO.csv",
    },
  ];
  const StatusName = [
    {
      data: "Active",
      data: "Inactive",
      data: "Inactive",
    },
  ];
  const Datalogger = [{ data: "dummy" }];
  const handleDropdownChange = (event, value, name) => {
    setstdField({ ...stdField, [name]: value });
  };
  const getSiteCall = async () => {
    try {
      let data = await Equipmenttypedropdown();
      setequipmentId(data);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchstandardParameter = async () => {
    try {
      let data = await GetFileParamCoff();
      setParameterName(data);
    } catch (e) {
      console.error(e);
    }
  };
  const handleAutocompleteChange = (newValue, item) => {
    setRowValues((prev) => ({
      ...prev,
      [item]: { ...prev[item], parameter: newValue },
    }));
  };
  const FallBack = () => {
    const isLoggerTable = value === 2;

    const forLogger = dataLoggerValue
      ? tablemap
        ? "No data logger configured yet."
        : null
      : "Select a site and data logger to list the configuration.";

    const forLoggerMaster = site
      ? tablevalue
        ? "No data logger master configured yet."
        : null
      : "Select a site to list data logger masters.";

    return isLoggerTable ? forLogger : forLoggerMaster;
  };
  const handleTxtChange = (event, name) => {
    setstdField({ ...stdField, [name]: event.target.value });
  };
  const fetchdata = async () => {
    try {
      //   let data = await ();
      setFile(dummy);
    } catch (e) {
      console.error(e);
    }
  };
  console.log(ParameterName);

  function getOptions(propName) {
    let option = [];
    console.log(propName);

    switch (propName) {
      case "equipmentName":
        return (option = equipmentName?.map((option) => option.equipmentType));
      case "parameterName":
        return (option = ParameterName?.map(
          (option) => option.stdParameterName
        ));
      case "dataloggerName":
        return (option = Datalogger?.map((option) => option.data));

      case "Status":
        return (option = StatusName?.map((option) => option.data));

      case "configfile":
        return (option = config?.map((option) => option.data));
      default:
    }
    return option;
  }
  const handleGetFile = () => {
    SetOpenmadal(true);
  };
  const handleClose = () => {
    SetOpenmadal(false);
  };
  const handleChangee = (newValue) => {
    setValue(newValue);
  };
  const hanldeOpenMaster = () => {
    setOpenMaster(true);
    seteditlog(false);
    // setEditvalue("");
  };
  const handleCloseMaster = () => {
    setstdField(initialState);
    // setEditvalue([]);
    setOpenMaster(false);
  };
  const table = "";
  const tabsData = [
    { name: " Master Logger", value: 0 },
    { name: "Data Logger", value: 2 },
  ];
  const headCells = [
    {
      label: "Site Name",
      id: "siteName",
      view: true,
      default: true,
    },
    {
      label: "Data Logger",
      id: "dataLoggerName",
      view: true,
    },
    {
      label: "Status",
      id: "status",
      view: true,
    },
    {
      label: "Action",
      id: "action",
      view: true,
      default: true,
    },
  ];
  const headCell = [
    {
      label: "Site Name",
      id: "siteName",
      view: true,
      default: true,
    },
    {
      label: "Data Logger",
      id: "dataLoggerName",
      view: true,
    },
    {
      label: "File path",
      id: "filePath",
      view: true,
    },
    {
      label: "Action",
      id: "action",
      view: true,
      default: true,
    },
  ];
  let head = value === 2 ? headCell : headCells;

  const handleTxtChangee = (e, index) => {
    const { value } = e.target;
    setstdField((prev) => {
      const updatedFields = [...prev];
      updatedFields[index].coeffient = value;
      return updatedFields;
    });
  };
  
  const handleUpdate = async (value) => {};
  const handleSubmit = async (value) => {};
  const handleEditOpen = async (value) => {};
  return (
    <div>
      <CustomSnackbar
        open={snack.open}
        onClose={handleSnackClose}
        autoHideDuration={5000}
        severity={snack.severity}
        message={snack.message}
      />
      <div>
        <Box
          sx={{
            margin: "0 0.5% 0 0.5%",
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              mr: 0,
              mb: 1,
              // mt: 2,
              borderRadius: 0,
              width: "100%",
              height: "7.5vh",
            },
          }}
        >
          <Card
            elevation={0}
            style={{
              display: "flex",
              width: "101%",
              height: "6.5vh",
              borderRadius: "2px 2px 0px 0px",
              userSelect: "none",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomBreadcrumbs
                paths={paths || []}
                separatorSize="18px"
                fontSize="14px"
              />
            </div>
            <div style={{ margin: "1%" }}>
              <Stack spacing={2} direction="row">
                <Box sx={{ width: "100%" }}>
                  <TabsComponent
                    isLogger={true}
                    loggerState={value}
                    tabs={tabsData}
                    onTabChange={(val) => handleChangee(val)}
                  />
                </Box>

                <Tooltip
                  key={"add master"}
                  title={"Add New Master"}
                  userSelect="none"
                  followCursor
                  arrow
                >
                  <IconButton
                    variant="text"
                    style={{
                      textTransform: "capitalize",
                      color: "#370c49",
                      padding: "0",
                      fontSize: "40px",
                      borderRadius: "20px 20px 20px 20px",
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    // onClick={(event) => handleClick(data.value, event)}
                  >
                    <AddCircleOutlineSharpIcon
                      onClick={hanldeOpenMaster}
                      style={{ color: "#004AFF", fontSize: "20px" }}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            </div>
          </Card>
        </Box>
      </div>
      <div>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
              width: "101%",
              height: "10vh",
            },
          }}
        >
          <Card
            elevation={0}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <Grid
                container
                spacing={0}
                columns={24}
                style={{ marginLeft: "2%" }}
              >
                <Grid item xs={10}>
                  <Typography className="modal-typo">Master Logger</Typography>
                  <div style={{ marginTop: "1%" }}>
                    <Autocomplete
                      disableClearable
                      disablePortal
                      size="small"
                      options={getOptions("masterNamedata")}
                      onChange={(event, value) =>
                        handleDropdownChange(event, value, "masterNamedata")
                      }
                      sx={{ width: "15vw" }}
                      value={stdField.masterNamedata}
                      renderInput={(params) => (
                        <div>
                          <TextField {...params} placeholder={"E.g: IFP"} />
                        </div>
                      )}
                      classes={{
                        option: "autocomplete",
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={10}>
                  <Typography className="modal-typo">File Path</Typography>
                  <div style={{ marginTop: "1%" }}>
                    <Autocomplete
                      disableClearable
                      disablePortal
                      size="small"
                      options={getOptions("filepath")}
                      onChange={(event, value) =>
                        handleDropdownChange(event, value, "filepath")
                      }
                      sx={{ width: "15vw" }}
                      value={stdField.filepath}
                      renderInput={(params) => (
                        <div>
                          <TextField {...params} placeholder={"E.g: IFP"} />
                        </div>
                      )}
                      classes={{
                        option: "autocomplete",
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    className="modal-typo"
                    style={{ visibility: "hidden" }}
                  >
                    Config File
                  </Typography>
                  <div>
                    <Button
                      variant="contained"
                      sx={{
                        width: "10vw",
                        marginLeft: "10px",
                        textTransform: "capitalize",
                        borderRadius: "19px",
                      }}
                      startIcon={<FileUploadOutlinedIcon />}
                      onClick={handleGetFile}
                    >
                      Get File
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Box>
      </div>

      <div>
        {" "}
        <Modal
          open={Openmadal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={"styleModall"}>
            <Paper elevation={0} style={{ borderRadius: "20px" }}>
              <div
                style={{
                  backgroundColor: "rgb(246, 248, 252)",
                  borderRadius: "20px",
                }}
              >
                <CardHeader
                  sx={{
                    padding: "20px 20px 20px 20px",
                    // background: CheckForLight()
                    //   ? "rgb(246, 248, 252)"
                    //   : "#4f4f4f",
                  }}
                  action={
                    <Stack spacing={2} direction="row">
                      <Button
                        startIcon={<CancelIcon />}
                        color="primary"
                        variant="contained"
                        style={{
                          marginLeft: "10px",
                          textTransform: "capitalize",
                          borderRadius: "19px",
                        }}
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        Close
                      </Button>
                    </Stack>
                  }
                  title="Data Logger"
                />
                <Divider style={{ borderColor: "#888" }} />
                <Card
                  style={
                    {
                      // Enables scrolling if content exceeds height
                    }
                  }
                >
                  <TableContainer
                    component={Paper}
                    sx={{ maxHeight: "60vh", overflowY: "auto" }}
                  >
                    <Table>
                    <TableBody>
                        <TableRow>
                          <TableCell
                          >
                            <Typography className="modal-typo">S.No</Typography>
                          </TableCell>
                          <TableCell
                           
                          >
                            <Typography className="modal-typo"> Parameter Name</Typography>
                            
                           
                          </TableCell>
                          <TableCell
                           
                          >
                             <Typography className="modal-typo">Standard Parameter</Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              position: "sticky",
                              color:"7d827b"
                            
                            }}
                          >
                            <Typography className="modal-typo">CoEfficient</Typography>
                          </TableCell>
                        </TableRow>
                        </TableBody>
                      <TableBody>
  {File?.map((item, index) => (
    <TableRow key={index}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>{item}</TableCell>
      <TableCell>
      <Autocomplete
  disablePortal
  value={rowValues[item]?.parameter || "dummy"} // Ensures a fallback value
  onChange={(event, newValue) => handleAutocompleteChange(newValue, item)}
  sx={{ width: 300 }}
  options={getOptions("parameterName")}
  renderInput={(params) => (
    <TextField {...params} placeholder="parameter" variant="standard" />
  )}
/>
      </TableCell>
      <TableCell>
        <TextField
          id="standard-basic"
          placeholder="Co-Efficient"
          value={stdField?.coeffient}
          // onChange={(e) => handleTxtChangee(e, index)}
          variant="standard"
        />
      </TableCell>
    </TableRow>
  ))}
</TableBody>

                    </Table>
                  </TableContainer>
                </Card>
                <Divider style={{ borderColor: "#888" }} />
              </div>
              <CardHeader
                sx={{
                  padding: "20px 20px 20px 20px",
                  // background: CheckForLight()
                  //   ? "rgb(246, 248, 252)"
                  //   : "#4f4f4f",
                }}
                action={
                  <Stack spacing={2} direction="row">
                    <Button
                      startIcon={
                        toBackend ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <SaveIcon />
                        )
                      }
                      disabled={toBackend}
                      type={"submit"}
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: "10px",
                        textTransform: "capitalize",
                        borderRadius: "19px",
                      }}
                      // onClick={() => {
                      //   handleSubmit();
                      // }}
                    >
                      {!toBackend ? "Save" : "Saving..."}
                    </Button>

                    <Button
                      startIcon={<CancelIcon />}
                      color="primary"
                      variant="contained"
                      style={{
                        marginLeft: "10px",
                        textTransform: "capitalize",
                        borderRadius: "19px",
                        visibility: "hidden",
                      }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Close
                    </Button>
                  </Stack>
                }
                title=""
              />
            </Paper>
          </Box>
        </Modal>
      </div>
      <div>
        <Formik
          key={editlog ? "edit" : "create"}
          enableReinitialize={true}
          initialValues={stdField}
          validationSchema={MasterTypeSchema}
          onSubmit={async (values, { resetForm }) => {
            let submitValue = values;
            if (editlog) {
              const result = await handleUpdate(submitValue);
              if (result === 1) {
                resetForm();
              }
            } else {
              const result = await handleSubmit(submitValue);
              if (result === 1) {
                resetForm();
              }
            }
          }}
        >
          {({ values, handleChange, handleSubmit, handleReset }) => (
            <Form>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openMaster}
                // onClose={() => {
                //   handleCloseMaster();
                //   handleReset();
                // }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
              >
                <Fade in={openMaster}>
                  <Box
                    className={"styleModal"}
                    // sx={{ bgcolor: 'background.paper' }}
                    sx={{
                      border: "none",
                      outline: "none",
                    }}
                  >
                    <Card
                      sx={{
                        borderRadius: "20px",
                        // height: '68vh',
                        height: "fit-content",
                      }}
                    >
                      <CardHeader
                        sx={{
                          padding: "20px 20px 20px 20px",
                          // background: CheckForLight()
                          //   ? "rgb(246, 248, 252)"
                          //   : "#4f4f4f",
                        }}
                        action={
                          <Stack spacing={2} direction="row">
                            {editlog ? (
                              <Button
                                startIcon={
                                  toBackend ? (
                                    <CircularProgress
                                      size={20}
                                      color="inherit"
                                    />
                                  ) : (
                                    <SaveIcon />
                                  )
                                }
                                disabled={toBackend}
                                type={"submit"}
                                color="primary"
                                variant="contained"
                                style={{
                                  marginLeft: "10px",
                                  textTransform: "capitalize",
                                  borderRadius: "19px",
                                }}
                                onClick={() => {
                                  handleSubmit();
                                }}
                              >
                                {!toBackend ? "Update" : "Updating..."}
                              </Button>
                            ) : (
                              <Button
                                startIcon={
                                  toBackend ? (
                                    <CircularProgress
                                      size={20}
                                      color="inherit"
                                    />
                                  ) : (
                                    <SaveIcon />
                                  )
                                }
                                disabled={toBackend}
                                type={"submit"}
                                color="primary"
                                variant="contained"
                                style={{
                                  marginLeft: "10px",
                                  textTransform: "capitalize",
                                  borderRadius: "19px",
                                }}
                                onClick={() => {
                                  handleSubmit();
                                }}
                              >
                                {!toBackend ? "Save" : "Saving..."}
                              </Button>
                            )}
                            <Button
                              startIcon={<CancelIcon />}
                              color="primary"
                              variant="contained"
                              style={{
                                marginLeft: "10px",
                                textTransform: "capitalize",
                                borderRadius: "19px",
                              }}
                              onClick={() => {
                                handleCloseMaster();
                                handleReset();
                              }}
                            >
                              Close
                            </Button>
                          </Stack>
                        }
                        title="Add New Master"
                      />
                      <Divider style={{ borderColor: "#888" }} />

                      <div style={{ marginLeft: "2%", marginTop: "2%" }}>
                        <Card
                          sx={{
                            overflowY: "visible",
                            marginLeft: "-2%",
                            // paddingLeft: '-1vw',
                            marginTop: "-2%",
                            paddingBottom: "2%",
                            scrollBehavior: "smooth",
                            scrollbarGutter: "stable",
                            scrollbarWidth: "thin",
                            "&::-webkit-scrollbar": {
                              width: "0.4em",
                            },
                            "&::-webkit-scrollbar-track": {
                              background: "#f1f1f1",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#888",
                              borderRadius: "20px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                              background: "#555",
                            },
                          }}
                          elevation={0}
                        >
                          <CardContent sx={{ paddingLeft: "2.5%" }}>
                            <Grid
                              container
                              spacing={2}
                              rowSpacing={2}
                              columns={16}
                            >
                              <Grid item xs={4}>
                                <Typography className="modal-typo">
                                  Equipment Type
                                </Typography>
                                <div style={{ marginTop: "5%" }}>
                                  <Field
                                    name={"siteName"}
                                    render={({ field, form }) => (
                                      <Autocomplete
                                        disableClearable
                                        options={getOptions("equipmentName")}
                                        value={stdField.equipmentName}
                                        onChange={(event, value) => {
                                          handleDropdownChange(
                                            event,
                                            value,
                                            "equipmentName"
                                          );
                                        }}
                                        size="small"
                                        sx={{ width: "12vw" }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="E.g: IFP"
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    name={"siteName"}
                                    component="div"
                                    className="errorStyle"
                                    style={{
                                      color: "red",
                                      marginTop: "1%",
                                      textAlign: "left",
                                      marginLeft: "0%",
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography className="modal-typo">
                                  Master Name
                                </Typography>
                                <div style={{ marginTop: "5%" }}>
                                  <Field
                                    as={TextField}
                                    size="small"
                                    variant="outlined"
                                    name="masterName"
                                    value={stdField.masterName}
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleTxtChange(e, "masterName");
                                    }}
                                    sx={{ width: "12vw" }}
                                    placeholder="Enter Master Name"
                                  />
                                  <ErrorMessage
                                    name={" Master Name"}
                                    component="div"
                                    className="errorStyle"
                                    style={{
                                      color: "red",
                                      marginTop: "1%",
                                      textAlign: "left",
                                      marginLeft: "0%",
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography className="modal-typo">
                                  Status
                                </Typography>
                                <div style={{ marginTop: "5%" }}>
                                  <Field
                                    name={"siteName"}
                                    render={({ field, form }) => (
                                      <Autocomplete
                                        disableClearable
                                        options={getOptions("Status")}
                                        value={stdField.Status}
                                        onChange={(event, value) => {
                                          handleDropdownChange(
                                            event,
                                            value,
                                            "Status"
                                          );
                                        }}
                                        size="small"
                                        sx={{ width: "12vw" }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="E.g: IFP"
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                  <ErrorMessage
                                    name={"Status"}
                                    component="div"
                                    className="errorStyle"
                                    style={{
                                      color: "red",
                                      marginTop: "1%",
                                      textAlign: "left",
                                      marginLeft: "0%",
                                    }}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </div>
                    </Card>
                  </Box>
                </Fade>
              </Modal>
            </Form>
          )}
        </Formik>
      </div>
      <div>
        {" "}
        {internalLoader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20%",
            }}
          >
            <FadeLoader color="#000440" loading={true} />
          </div>
        ) : (
          <div>
            <TableTemplate
              addButton={"datalogger"}
              header={head}
              rowsValue={table}
              handleEditPopupOpen={(val) => handleEditOpen(val)}
              userRole={Report[0]}
              fallbackText={FallBack()}
            />
          </div>
        )}
      </div>
    </div>
  );
}
