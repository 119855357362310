import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  Tooltip,
  Stack,
  Table,
  TableCell,
  CardContent,
  Backdrop,
  Card,
  Fade,
  Modal,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import {
  fetchSiteDetails,
  fetchTicketState,
  fetchAssignDetail,
  AssignlistDropdown,
  postTicketFilterTable,
  postTicketbarchart,
  fetchTicketCategory,
} from "../../Api/TicketApi";
import CustomBreadcrumbs from "../util/components/CustomBread";
import CustomSnackbar from "../util/components/CustomSnackbar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { color } from "highcharts";

const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const dayjs = require("dayjs");
export default function Ftpgetfile(props) {
  const initialState = {
    // hostName: "",
    // port: "",
    // userName: "",
    ticketStatus: "",
    assignedTo: "",
    priority: "",
    ticketcategory: "",
    basedon: "",
    siteName: "",
  };
  const { ticketfilter, filterstatus, filterbarcharts } = props;

  const [stdField, setstdField] = useState(initialState);
  console.log(stdField);
  const [Sitecategory, setSitecategory] = useState([]);
  const [siteIdName, setSiteId] = useState([]);
  const [Assign, setAssignevalue] = useState([]);
  const [ticketstatus, setAssignuser] = useState([]);
  const [state, setstate] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [datedatas, setDatedatas] = useState("");
  const [datedata, setDatedata] = useState("");
  const [selectedDatee, setSelectedDatee] = useState(null);
  const initialSnack = { open: false, severity: "error", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const [toBackend, setToBackend] = useState(false);
  console.log(datedata);

  console.log(snack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Cumulative ", path: "ftppget" },
  ];
  const Item = styled(Paper)(({ theme }) => ({}));
  /*------------------------- handle save --------------------------------------------*/

  useEffect(() => {
    getSiteCall();
    fetchstateCall();
    fetchCategoryCall();
    fetchAssignDetails();
  }, []);
  const fetchstateCall = async (id) => {
    try {
      let data = await fetchTicketState(id);
      setstate(data);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchAssignDetails = async () => {
    try {
      const data = await fetchAssignDetail();
      setAssignuser(data);
    } catch {}
  };
  const fetchCategoryCall = async (id) => {
    try {
      let data = await fetchTicketCategory(id);
      setSitecategory(data);
    } catch (e) {
      console.error(e);
    }
  };
  const handleDropdownChange = async (event, value, name) => {
    console.log(name);

    if (name === "siteName") {
      let siteIdFilter = siteIdName?.filter((data) => data.siteName === value);
      let siteId = siteIdFilter.length > 0 ? siteIdFilter[0].siteId : null;

      if (siteId) {
        try {
          let data = await AssignlistDropdown(siteId);
          setAssignevalue(data);
        } catch (e) {
          console.error(e);
        }
      }
    }

    setstdField({ ...stdField, [name]: value });
  };
  const handleDropdownChangee = (event, value) => {
    setstdField((prev) => ({ ...prev, assignedTo: value })); // Store selected values
  };
  const getSiteCall = async (id) => {
    try {
      let data = await fetchSiteDetails(id);
      setSiteId(data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleTxtChange = (event, name) => {
    setstdField({ ...stdField, [name]: event.target.value });
  };

  const handleDateChange = (date, name) => {
    console.log(date, name);
    setstdField({ ...stdField, [name]: new Date(date) });
    const selectedDate = new Date(date);
    selectedDate.setDate(selectedDate.getDate(), +1);
    console.log(selectedDate);
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setDatedatas(formattedDate);
    setSelectedDate(selectedDate);
  };

  const handleDatepicChange = (date) => {
    const selectedDatee = new Date(date);
    selectedDatee.setDate(selectedDatee.getDate(), +1); // Example modification
    setSelectedDatee(selectedDatee);
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setDatedata(formattedDate);
  };
  const Priority = [
    { label: "High", value: "3" },
    { label: "Medium", value: "2" },
    { label: "Low", value: "1" },
  ];
  const basedon = [{ data: "createdDate" }, { data: "scheduleOn" }];

  function getOptions(propName) {
    let option = [];
    console.log(propName);

    switch (propName) {
      case "siteName":
        return (option = siteIdName?.map((option) => option.siteName));
      case "priority":
        return (option = Priority?.map((option) => option.label));

      case "ticketcategory":
        return (option = Sitecategory?.map((option) => option.categoryName));
      case "basedon":
        return (option = basedon?.map((option) => option.data));
      case "ticketstate":
        return (option = state?.map((option) => option.stateName));
      case "assignedTo":
        return (option = Assign?.map((option) => option.userName));
      case "ticketStatus":
        return (option = ticketstatus?.map((option) => option.statusName));
      default:
    }
    return option;
  }
  const handleSubmit = async (value) => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === stdField.siteName;
    });
    let statusIdFilter = ticketstatus?.filter((data) => {
      return data.statusName === stdField.ticketStatus;
    });
    console.log(statusIdFilter);

    const filteredAssign = Assign.filter((assignedTo) =>
      stdField.assignedTo.includes(assignedTo.userName)
    );

    const filteredAssignIds = filteredAssign.map(
      (assignedTo) => assignedTo.userId
    );

    console.log(stdField.ticketcategory);
    console.log(Sitecategory);

    let ticketcategory = Sitecategory?.filter((data) => {
      return data.categoryName === stdField.ticketcategory;
    });
    console.log(ticketcategory);

    const addDays = (date, days) => {
      if (!date) return "";
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result.toISOString().substring(0, 10);
    };

    const fromDate = addDays(selectedDate, 1);
    const toDate = addDays(selectedDatee, 1);

    let data = {
      siteId: siteIdFilter[0]?.siteId,
      toDate: toDate,
      filterType: stdField.basedon,
      fromDate: fromDate,

      categoryId: ticketcategory[0]?.categoryId,
      ticketStatusId: statusIdFilter[0]?.ticketStatusId,
      assignedTo: filteredAssignIds,
      priority:
        stdField.priority === "Medium"
          ? 2
          : stdField.priority === "High"
          ? 3
          : stdField.priority === "Low"
          ? 1
          : "null",
    };
    data = Object.fromEntries(
      Object.entries(data).filter(
        ([_, value]) =>
          value !== null &&
          value !== undefined &&
          value !== "" &&
          value !== "null" &&
          value !== "[]" &&
          (!Array.isArray(value) || value.length > 0) // Check for empty arrays
      )
    );
    console.log(data);

    try {
      setToBackend(true);
      const responseData = await postTicketFilterTable(data);
      const response = await postTicketbarchart(data);
      filterbarcharts(response);
      setToBackend(false);
      const updateAssignedBy = (item) => {
        if (item.assignedBy === null) {
          item.assignedBy = "Assign";
        }
        if (Array.isArray(item.ticketTransactions)) {
          item.ticketTransactions.forEach((transaction) => {
            if (transaction.assignedBy === null) {
              transaction.assignedBy = "assign";
            }
          });
        }
        return item;
      };
      console.log(responseData);

      if (Array.isArray(responseData)) {
        const updatedData = responseData.map(updateAssignedBy);
        ticketfilter(updatedData);
        filterstatus(true);
      } else if (typeof responseData === "object" && responseData !== null) {
        const updatedData = updateAssignedBy(responseData);
        ticketfilter([updatedData]);
        filterstatus(true);
      }

      return 1;
    } catch (e) {
      //   setSnack(errorMsg.failure);

      return 0;
    }
  };
  const handlereset = () => {
    setstdField("");
    setSelectedDate("");
    setSelectedDatee("");
    setDatedatas("");
    setDatedata("");
  };
  console.log(selectedDate);

  return (
    <div>
      <div>
        <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Paper
            elevation={0}
            style={{
              width: "100%",
              borderRadius: "2px 2px 0px 0px",
              userSelect: "none",
              height: "40vh",
              padding: "25px",
            }}
          >
            <div>
              {/* <Typography style={{display:"flex",justifyContent:"center"}}>Customize Ticket Filter</Typography> */}
              <Grid container spacing={3} columns={9}>
                <Grid item xs={3}>
                  <Typography className="modal-typo">From Date*</Typography>
                  <div style={{ marginTop: "1%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        size="small"
                        sx={{
                          width: "13vw",
                          "& .MuiInputBase-input": {
                            color: datedatas ? "black" : "gray",
                          },
                        }} // Prevents errors and handles null values
                        format="DD/MM/YYYY"
                        value={
                          datedatas ? dayjs(datedatas, "DD/MM/YYYY") : null
                        }
                        onChange={(value) =>
                          handleDateChange(value, "fromdate")
                        }
                        slotProps={{
                          textField: {
                            size: "small",
                            error: false,
                            // Prevents red border due to validation
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="modal-typo">To Date*</Typography>
                  <div style={{ marginTop: "1%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        size="small"
                        sx={{
                          width: "13vw",
                          "& .MuiInputBase-input": {
                            color: datedata ? "black" : "gray",
                          },
                        }}
                        format="DD/MM/YYYY"
                        value={datedata ? dayjs(datedata, "DD/MM/YYYY") : null}
                        disabled={!selectedDate}
                        shouldDisableDate={(date) => {
                          const minDate = dayjs(selectedDate).startOf("day");
                          const maxDate = minDate.add(1, "month").endOf("day");
                          return date.isBefore(minDate);
                        }}
                        onChange={handleDatepicChange}
                        slotProps={{
                          textField: {
                            size: "small",
                            error: false,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>

                <Grid item xs={3}>
                  <Typography className="modal-typo">Site Name*</Typography>
                  <div style={{ marginTop: "1%" }}>
                    <Autocomplete
                      disableClearable
                      disablePortal
                      // id={`combo-box-demo-${index}`}
                      size="small"
                      options={getOptions("siteName")}
                      onChange={(event, value) =>
                        handleDropdownChange(event, value, "siteName")
                      }
                      sx={{ width: "13vw" }}
                      value={stdField.siteName}
                      renderInput={(params) => (
                        <div>
                          <TextField {...params} placeholder={"E.g: IFP"} />
                        </div>
                      )}
                      classes={{
                        option: "autocomplete",
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="modal-typo">Based On*</Typography>
                  <div style={{ marginTop: "1%" }}>
                    <Autocomplete
                      disableClearable
                      disablePortal
                      // id={`combo-box-demo-${index}`}
                      size="small"
                      options={getOptions("basedon")}
                      onChange={(event, value) =>
                        handleDropdownChange(event, value, "basedon")
                      }
                      sx={{ width: "13vw" }}
                      value={stdField.basedon}
                      renderInput={(params) => (
                        <div>
                          <TextField
                            {...params}
                            placeholder={"E.g: createdDate"}
                          />
                        </div>
                      )}
                      classes={{
                        option: "autocomplete",
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="modal-typo">
                    Ticket Category
                  </Typography>
                  <div style={{ marginTop: "1%" }}>
                    <Autocomplete
                      disableClearable
                      disablePortal
                      // id={`combo-box-demo-${index}`}
                      size="small"
                      options={getOptions("ticketcategory")}
                      onChange={(event, value) =>
                        handleDropdownChange(event, value, "ticketcategory")
                      }
                      sx={{ width: "13vw" }}
                      value={stdField.ticketcategory}
                      renderInput={(params) => (
                        <div>
                          <TextField
                            {...params}
                            placeholder={"E.g: Inverter Down"}
                          />
                        </div>
                      )}
                      classes={{
                        option: "autocomplete",
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={3}>
                  <Typography className="modal-typo">Priority</Typography>
                  <div style={{ marginTop: "1%" }}>
                    <Autocomplete
                      disableClearable
                      disablePortal
                      // id={`combo-box-demo-${index}`}
                      size="small"
                      options={getOptions("priority")}
                      onChange={(event, value) =>
                        handleDropdownChange(event, value, "priority")
                      }
                      sx={{ width: "13vw" }}
                      value={stdField.priority}
                      renderInput={(params) => (
                        <div>
                          <TextField {...params} placeholder={"E.g: Medium"} />
                        </div>
                      )}
                      classes={{
                        option: "autocomplete",
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="modal-typo">Assigned </Typography>
                  <div style={{ marginTop: "1%" }}>
                    <Autocomplete
                      multiple
                      disableClearable
                      disablePortal
                      disabled={!stdField.siteName}
                      size="small"
                      options={getOptions("assignedTo")}
                      onChange={(event, value) =>
                        handleDropdownChangee(event, value)
                      }
                      sx={{ width: "13vw" }}
                      value={stdField.assignedTo || []}
                      renderTags={(value) => {
                        if (value.length === 0) return null;

                        const truncatedValue =
                          value[0].length > 15
                            ? value[0].substring(0, 15) + "..."
                            : value[0];

                        return (
                          <div
                            style={{
                              maxWidth: "12vw",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              padding: "5px",
                              marginRight: 5,
                            }}
                          >
                            {truncatedValue}{" "}
                            {value.length > 1 ? `+${value.length - 1}` : ""}
                          </div>
                        );
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckBox fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={
                            stdField.assignedTo?.length === 0 ? "Abitha" : ""
                          }
                          sx={{
                            "& .MuiInputBase-input": {
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="modal-typo">Ticket State</Typography>
                  <div style={{ marginTop: "1%" }}>
                    <Autocomplete
                      disableClearable
                      disablePortal
                      // id={`combo-box-demo-${index}`}
                      size="small"
                      options={getOptions("ticketStatus")}
                      onChange={(event, value) =>
                        handleDropdownChange(event, value, "ticketStatus")
                      }
                      sx={{ width: "13vw" }}
                      value={stdField.ticketStatus}
                      renderInput={(params) => (
                        <div>
                          <TextField {...params} placeholder={"E.g: Created"} />
                        </div>
                      )}
                      classes={{
                        option: "autocomplete",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div style={{ padding: "30px" }}>
              <Stack
                direction="row"
                spacing={2}
                style={{
                  justifyContent: "center",
                }}
              >
                <Item>
                  <div>
                    <Button
                      style={{ textTransform: "capitalize" }}
                      variant="contained"
                      // onClick={handleSubmit}
                      disabled={
                        !(
                          stdField.siteName &&
                          selectedDatee &&
                          selectedDate &&
                          stdField.basedon
                        )
                      }
                      onClick={handleSubmit}
                    >
                      {toBackend ? "Viewing..." : "View "}
                    </Button>
                  </div>
                </Item>

                <Item>
                  <Button
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                    onClick={handlereset}
                  >
                    Reset
                  </Button>
                </Item>
              </Stack>
            </div>
          </Paper>
        </Box>
      </div>
    </div>
  );
}
