import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Grid2,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  fetchEquipDropdownBySite,
  fetchSiteDropdownByUser,
  fetchParamsDropdownByLogger,
} from "../../../Api/CommonApi";
import {
  ActualVsExpected,
  CO2Avioded,
  fetchDailyGenerationEira,
  fetchParameterComparisonEira,
} from "../../../Api/DataAnalystApi";
import moment from "moment";
import useGenerateSeries from "../../util/hooks/useGenerateSeries";
import { LargeChartEiraVersion } from "./LargeChart-ev";
import FadeLoader from "react-spinners/FadeLoader";
import oilDrum from "../../../images/barrels.png";
import GasPipe from "../../../images/gas.png";
import CoalCart from "../../../images/coal.png";
import { removeQuestionMark } from "../../util/common/CommanFunction";
import {
  decryptPaddedQuery,
  paddedEncryptQuery,
} from "../../util/security/Cipher";

const weatherCode = {
  0: {
    day: {
      description: "Sunny",
      image: "http://openweathermap.org/img/wn/01d@4x.png",
    },
    night: {
      description: "Clear",
      image: "http://openweathermap.org/img/wn/01n@4x.png",
    },
  },
  1: {
    day: {
      description: "Mainly Sunny",
      image: "http://openweathermap.org/img/wn/01d@4x.png",
    },
    night: {
      description: "Mainly Clear",
      image: "http://openweathermap.org/img/wn/01n@4x.png",
    },
  },
  2: {
    day: {
      description: "Partly Cloudy",
      image: "http://openweathermap.org/img/wn/02d@4x.png",
    },
    night: {
      description: "Partly Cloudy",
      image: "http://openweathermap.org/img/wn/02n@4x.png",
    },
  },
  3: {
    day: {
      description: "Cloudy",
      image: "http://openweathermap.org/img/wn/03d@4x.png",
    },
    night: {
      description: "Cloudy",
      image: "http://openweathermap.org/img/wn/03n@4x.png",
    },
  },
  45: {
    day: {
      description: "Foggy",
      image: "http://openweathermap.org/img/wn/50d@4x.png",
    },
    night: {
      description: "Foggy",
      image: "http://openweathermap.org/img/wn/50n@4x.png",
    },
  },
  48: {
    day: {
      description: "Rime Fog",
      image: "http://openweathermap.org/img/wn/50d@4x.png",
    },
    night: {
      description: "Rime Fog",
      image: "http://openweathermap.org/img/wn/50n@4x.png",
    },
  },
  51: {
    day: {
      description: "Light Drizzle",
      image: "http://openweathermap.org/img/wn/09d@4x.png",
    },
    night: {
      description: "Light Drizzle",
      image: "http://openweathermap.org/img/wn/09n@4x.png",
    },
  },
  53: {
    day: {
      description: "Drizzle",
      image: "http://openweathermap.org/img/wn/09d@4x.png",
    },
    night: {
      description: "Drizzle",
      image: "http://openweathermap.org/img/wn/09n@4x.png",
    },
  },
  55: {
    day: {
      description: "Heavy Drizzle",
      image: "http://openweathermap.org/img/wn/09d@4x.png",
    },
    night: {
      description: "Heavy Drizzle",
      image: "http://openweathermap.org/img/wn/09n@4x.png",
    },
  },
  56: {
    day: {
      description: "Light Freezing Drizzle",
      image: "http://openweathermap.org/img/wn/09d@4x.png",
    },
    night: {
      description: "Light Freezing Drizzle",
      image: "http://openweathermap.org/img/wn/09n@4x.png",
    },
  },
  57: {
    day: {
      description: "Freezing Drizzle",
      image: "http://openweathermap.org/img/wn/09d@4x.png",
    },
    night: {
      description: "Freezing Drizzle",
      image: "http://openweathermap.org/img/wn/09n@4x.png",
    },
  },
  61: {
    day: {
      description: "Light Rain",
      image: "http://openweathermap.org/img/wn/10d@4x.png",
    },
    night: {
      description: "Light Rain",
      image: "http://openweathermap.org/img/wn/10n@4x.png",
    },
  },
  63: {
    day: {
      description: "Rain",
      image: "http://openweathermap.org/img/wn/10d@4x.png",
    },
    night: {
      description: "Rain",
      image: "http://openweathermap.org/img/wn/10n@4x.png",
    },
  },
  65: {
    day: {
      description: "Heavy Rain",
      image: "http://openweathermap.org/img/wn/10d@4x.png",
    },
    night: {
      description: "Heavy Rain",
      image: "http://openweathermap.org/img/wn/10n@4x.png",
    },
  },
  66: {
    day: {
      description: "Light Freezing Rain",
      image: "http://openweathermap.org/img/wn/10d@4x.png",
    },
    night: {
      description: "Light Freezing Rain",
      image: "http://openweathermap.org/img/wn/10n@4x.png",
    },
  },
  67: {
    day: {
      description: "Freezing Rain",
      image: "http://openweathermap.org/img/wn/10d@4x.png",
    },
    night: {
      description: "Freezing Rain",
      image: "http://openweathermap.org/img/wn/10n@4x.png",
    },
  },
  71: {
    day: {
      description: "Light Snow",
      image: "http://openweathermap.org/img/wn/13d@4x.png",
    },
    night: {
      description: "Light Snow",
      image: "http://openweathermap.org/img/wn/13n@4x.png",
    },
  },
  73: {
    day: {
      description: "Snow",
      image: "http://openweathermap.org/img/wn/13d@4x.png",
    },
    night: {
      description: "Snow",
      image: "http://openweathermap.org/img/wn/13n@4x.png",
    },
  },
  75: {
    day: {
      description: "Heavy Snow",
      image: "http://openweathermap.org/img/wn/13d@4x.png",
    },
    night: {
      description: "Heavy Snow",
      image: "http://openweathermap.org/img/wn/13n@4x.png",
    },
  },
  77: {
    day: {
      description: "Snow Grains",
      image: "http://openweathermap.org/img/wn/13d@4x.png",
    },
    night: {
      description: "Snow Grains",
      image: "http://openweathermap.org/img/wn/13n@4x.png",
    },
  },
  80: {
    day: {
      description: "Light Showers",
      image: "http://openweathermap.org/img/wn/09d@4x.png",
    },
    night: {
      description: "Light Showers",
      image: "http://openweathermap.org/img/wn/09n@4x.png",
    },
  },
  81: {
    day: {
      description: "Showers",
      image: "http://openweathermap.org/img/wn/09d@4x.png",
    },
    night: {
      description: "Showers",
      image: "http://openweathermap.org/img/wn/09n@4x.png",
    },
  },
  82: {
    day: {
      description: "Heavy Showers",
      image: "http://openweathermap.org/img/wn/09d@4x.png",
    },
    night: {
      description: "Heavy Showers",
      image: "http://openweathermap.org/img/wn/09n@4x.png",
    },
  },
  85: {
    day: {
      description: "Light Snow Showers",
      image: "http://openweathermap.org/img/wn/13d@4x.png",
    },
    night: {
      description: "Light Snow Showers",
      image: "http://openweathermap.org/img/wn/13n@4x.png",
    },
  },
  86: {
    day: {
      description: "Snow Showers",
      image: "http://openweathermap.org/img/wn/13d@4x.png",
    },
    night: {
      description: "Snow Showers",
      image: "http://openweathermap.org/img/wn/13n@4x.png",
    },
  },
  95: {
    day: {
      description: "Thunderstorm",
      image: "http://openweathermap.org/img/wn/11d@4x.png",
    },
    night: {
      description: "Thunderstorm",
      image: "http://openweathermap.org/img/wn/11n@4x.png",
    },
  },
  96: {
    day: {
      description: "Light Thunderstorms With Hail",
      image: "http://openweathermap.org/img/wn/11d@4x.png",
    },
    night: {
      description: "Light Thunderstorms With Hail",
      image: "http://openweathermap.org/img/wn/11n@4x.png",
    },
  },
  99: {
    day: {
      description: "Thunderstorm With Hail",
      image: "http://openweathermap.org/img/wn/11d@4x.png",
    },
    night: {
      description: "Thunderstorm With Hail",
      image: "http://openweathermap.org/img/wn/11n@4x.png",
    },
  },
};

function getImageUrl(code) {
  return weatherCode[code]?.day?.image;
}

function getDescription(code) {
  return weatherCode[code]?.day?.description;
}

function getTempRangeString(dailyData, unitsData) {
  const unit = unitsData?.temperature_2m_max;

  const temperatureRanges = dailyData?.temperature_2m_min.map(
    (tempMin, index) => {
      const tempMax = dailyData?.temperature_2m_max[index];
      return `${tempMin} ${unit} - ${tempMax} ${unit}`;
    }
  );
  return temperatureRanges;
}

function getApparentTempRangeString(dailyData, unitsData) {
  const unit = unitsData?.apparent_temperature_max;

  const apparentTemperatureRanges = dailyData?.apparent_temperature_min.map(
    (tempMin, index) => {
      const tempMax = dailyData?.apparent_temperature_max[index];
      // return `${tempMin} ${unit} - ${tempMax} ${unit}`;
      return `${tempMin} - ${tempMax} ${unit}`;
    }
  );
  return apparentTemperatureRanges || [];
}

export const DashboardLargeEiraVersion = () => {
  const location = useLocation();
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`?${queryParam}`);
  const params = {};
  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }
  // console.log("Site", params);

  const [loading, setLoading] = React.useState(false);
  const [site, setSite] = React.useState("");
  const [siteList, setSiteList] = React.useState([]);
  const [equipments, setEquipments] = React.useState([]);
  const [dailyData, setDailyData] = React.useState([]);
  const [monthlyData, setMonthlyData] = React.useState([]);
  const [yearlyData, setYearlyData] = React.useState([]);
  const [actualVsExcepted, setActualVsExcepted] = React.useState([]);
  const [totalData, setTotalData] = React.useState([]);
  const [co2AvoidedData, setCo2AvoidedData] = React.useState({});
  const [forecastData, setForecastData] = React.useState({});
  const [equpAndParams, setEqupAndParams] = React.useState([]);
  const [timerValue, setTimerValue] = React.useState();
  const [mode, setMode] = React.useState(sessionStorage.getItem("theme"));
  const [autoRefresh, setAutoRefresh] = React.useState(true);

  const navigate = useNavigate();

  const toggleColorMode = () => {
    if (mode === "light") {
      sessionStorage.setItem("theme", "dark");
      setMode("dark");
    } else {
      sessionStorage.setItem("theme", "light");
      setMode("light");
    }
  };

  const intervalRef = React.useRef();

  useEffect(() => {
    getSiteCall();
  }, []);

  React.useEffect(() => {
    // Update site state when URL params change
    if (params?.siteName && params.siteName !== site) {
      setSite(params.siteName);
    }
  }, [params?.siteName]);

  useEffect(() => {
    intervalRef.current = setInterval(async () => {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      setSiteList(data);
      const val = site || data[0].siteName;

      let currentIndex = data.findIndex((site) => site.siteName === val);

      // console.log("Site", {
      //   params: params?.siteName,
      //   currentIndex,
      //   data,
      //   siteName: data[currentIndex + 1].siteName,
      //   val,
      // });

      let name;

      if (currentIndex !== -1 && currentIndex < data.length - 1) {
        name = data[currentIndex + 1].siteName;
        // console.log("Updating Site", name);
      } else {
        // console.log("resetting Site", data[0].siteName);
        name = data[0].siteName;
      }

      setLoading(true);
      try {
        await handleSite(null, name);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }, 30000); // 300,000 ms = 5 minutes
    return () => {
      clearInterval(intervalRef.current); // Clean up on unmount or autoRefresh change
    };
  }, [site]);

  const handleSite = async (data, val) => {
    setLoading(true);
    // console.log("Site", { val, siteList });

    const params = new URLSearchParams();

    params.set("siteName", val);
    const encryptedParams = paddedEncryptQuery(params.toString());
    navigate(`?${encryptedParams}`);

    setSite(val);
    await getDailyGen(val);
    await getMonthlyGen(val);
    await getTreePlantation(val);
    await getActualVsExcepted(val);
    await getTotalGen(val);
    await co2Avoided(val);
    await getForecastData(val);
    setLoading(false);
  };

  const getSiteCall = async () => {
    setLoading(true);
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      let equp = await fetchEquipDropdownBySite(data[0]?.siteId);
      setEquipments(equp);
      const dataFrequency = data[0].siteFrequency;
      setTimerValue(dataFrequency);
      const siteNameFromSession = params.siteName;

      const name = siteNameFromSession ? siteNameFromSession : data[0].siteName;
      const siteData = data.filter((site) => site.siteName === name);
      await getDailyGen(name, siteData[0]);
      await getMonthlyGen(name, siteData[0]);
      await getTreePlantation(name, siteData[0]);
      await getActualVsExcepted(name, siteData[0]);
      await getTotalGen(name, siteData[0]);
      await co2Avoided(name, siteData[0]);
      await getForecastData(name);
      setSiteList([...new Set(data)]);
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  async function getForecastData(name) {
    let id = sessionStorage.getItem("id");
    let siteList = await fetchSiteDropdownByUser(id);
    const siteData = siteList.filter((site) => site.siteName === name);
    const lat = siteData[0].latitude;
    const long = siteData[0].longitude;
    const url = `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${long}&daily=weather_code,temperature_2m_max,temperature_2m_min,apparent_temperature_max,apparent_temperature_min&timezone=auto&forecast_days=3`;
    let response = await fetch(url);

    const data = await response.json();
    console.log(data);

    setForecastData(data);
    return data;
  }

  async function getDailyGen(site, data) {
    const today = moment().format("YYYY-MM-DD");
    const { chartName, tableData, multichartData } = await getChartData(
      today,
      today,
      "daily",
      "Active Power",
      site,
      data
    );
    setDailyData(multichartData);
  }

  async function getMonthlyGen(site, data) {
    const from = moment().startOf("year").format("YYYY-MM-DD");
    const to = moment().endOf("year").format("YYYY-MM-DD");
    const { chartName, tableData, multichartData } = await getChartData(
      from,
      to,
      "yearly",
      "Daily Generation",
      site,
      data
    );
    setMonthlyData(tableData);
  }

  async function getTreePlantation(site, data) {
    const from = moment()
      .subtract(1, "year")
      .startOf("year")
      .format("YYYY-MM-DD");
    const to = moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD");
    const { chartName, tableData, multichartData } = await getChartData(
      from,
      to,
      "yearly",
      "CO2 Avoidance",
      site,
      data
    );
    setYearlyData(tableData);
  }
  async function getActualVsExcepted(site, data) {
    const from = moment()
      .subtract(7, "days")
      .startOf("day")
      .format("YYYY-MM-DD");
    const to = moment().endOf("day").format("YYYY-MM-DD");
    const { chartName, tableData, multichartData } = await getChartData(
      from,
      to,
      "custom",
      "Actual vs Expected",
      site,
      data
    );
    setActualVsExcepted(tableData);
  }

  async function getTotalGen(site, data) {
    const from = moment()
      .subtract(10, "year")
      .startOf("year")
      .format("YYYY-MM-DD");
    const to = moment().endOf("year").format("YYYY-MM-DD");

    const { chartName, tableData, multichartData } = await getChartData(
      from,
      to,
      "yearlyCum",
      "Daily Generation",
      site,
      data
    );
    setTotalData(tableData);
  }

  const co2Avoided = async (site, data) => {
    const from = moment()
      .subtract(10, "year")
      .startOf("year")
      .format("YYYY-MM-DD");
    const to = moment().endOf("year").format("YYYY-MM-DD");

    const { chartName, tableData, multichartData } = await getChartData(
      from,
      to,
      "yearlyCum",
      "CO2 Avoidance",
      site,
      data
    );

    function convertData(data) {
      // Initialize variables for the lowest timestamp and total CO2
      let lowestTimestamp = data[0]?.timestamp || null;
      let totalCO2 = 0;

      data.forEach((item) => {
        // Update the lowest timestamp
        if (item.timestamp < lowestTimestamp) {
          lowestTimestamp = item.timestamp;
        }
        // Accumulate the total CO2
        totalCO2 += item.co2;
      });

      // Return the converted object
      return {
        date: lowestTimestamp,
        total: totalCO2.toFixed(2),
      };
    }

    setCo2AvoidedData(convertData(tableData));
  };

  const getChartData = async (from, to, range, graphName, site, data) => {
    setLoading(true);
    setSite(site);
    let id = sessionStorage.getItem("id");
    let siteList = await fetchSiteDropdownByUser(id);

    let siteIdFilter = siteList?.filter((data) => {
      return data.siteName === site;
    });
    console.log(siteList, site);

    let equipmentIds = [];
    let equp = equipments;

    if (siteIdFilter[0]?.siteId) {
      equp = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);
      setEquipments(equp);
    }

    if (equp) {
      equipmentIds = equp
        ?.filter((obj) => {
          return [
            "Weather Station",
            "Energy Meter",
            "String Inverter",
            "Central Inverter",
          ]?.includes(obj.equipmentCategory);
        })
        .map((data) => {
          return data.equipmentId;
        });
    }

    let EiraDataModel = {
      fromDate: from,
      equipmentIds: equipmentIds,
      range: range,
      siteId: siteIdFilter[0]?.siteId,
      toDate: to,
      capacity: siteIdFilter[0]?.installationCapacity,
      energyFlag: 0,
      intervalMins: siteIdFilter[0]?.siteFrequency,
      energyGenBasedOn: siteIdFilter[0]?.energyGenBasedOn,
      GraphType: graphName,
    };

    let siteId = siteIdFilter[0]?.siteId;
    if (data) {
      const equp = await fetchEquipDropdownBySite(data.siteId);
      siteId = data.siteId;
      EiraDataModel.equipmentIds = equp
        ?.filter((obj) => {
          return [
            "Weather Station",
            "Energy Meter",
            "String Inverter",
            "Central Inverter",
          ]?.includes(obj.equipmentCategory);
        })
        .map((data) => {
          return data.equipmentId;
        });

      EiraDataModel.siteId = data.siteId;
      EiraDataModel.intervalMins = data.siteFrequency;
      EiraDataModel.energyGenBasedOn = data.energyGenBasedOn;
      EiraDataModel.capacity = data.installationCapacity;
    }

    let chartName, tableData, multichartData;
    try {
      async function createEquipmentParamsArray(siteId) {
        const id = siteId;
        setLoading(true);
        try {
          // Fetch the equipment dropdown data for the site
          const equip = await fetchEquipDropdownBySite(id);

          // Create a map to store equipment based on dataLoggerId
          const dataLoggerMap = equip.reduce((map, equipment) => {
            const { dataLoggerId } = equipment;
            if (!map[dataLoggerId]) {
              map[dataLoggerId] = [];
            }
            map[dataLoggerId].push(equipment);
            return map;
          }, {});

          const resultArray = [];

          // Iterate through the dataLoggerMap and fetch params for each dataLoggerId
          for (const [dataLoggerId, equipments] of Object.entries(
            dataLoggerMap
          )) {
            // Fetch parameters for the current dataLoggerId
            const params = await fetchParamsDropdownByLogger({
              dataloggerId: [Number(dataLoggerId)],
            });

            // For each equipment, create an object with `equ` and `params` keys
            equipments.forEach((equipment) => {
              resultArray.push({
                equ: equipment.displayName,
                params: params
                  .filter((param) => param.stdParameterName !== "Dummy")
                  .map((param) => `${param.stdParameterName}(${param.stdUom})`),
                category: equipment.equipmentCategory,
              });
            });
          }

          console.log("resultArray", resultArray);

          // Update the state with the resulting array
          setEqupAndParams(resultArray);
          return resultArray;
        } catch (e) {
          console.error(e);
          setEqupAndParams([]);
        }
      }

      const equipDetails = await fetchEquipDropdownBySite(siteId);
      await createEquipmentParamsArray(siteId);

      const equipArray = equipDetails
        .sort((a_name, b_name) => {
          const a = a_name.displayName;
          const b = b_name.displayName;
          if (a?.includes("-") && b?.includes("-")) {
            return +a.split("-").pop() - +b.split("-").pop();
          } else if (a?.includes("_") && b?.includes("_")) {
            return +a.split("_").pop() - +b.split("_").pop();
          }
          return a.localeCompare(b);
        })
        .filter((data) => {
          return data.equipmentCategory
            .toLocaleLowerCase()
            .includes("inverter");
        })
        .map((datav) => {
          return datav.displayName;
        });

      let filteredObjects = equipDetails?.filter((obj) =>
        equipArray.includes(obj.displayName)
      );

      const equipIds = filteredObjects?.map((data) => {
        return data.equipmentId;
      });

      const dataFrequency = siteIdFilter[0]?.siteFrequency ?? 5;

      let meter = equipIds;

      if (graphName === "Daily Generation") {
        try {
          let responseData = await fetchDailyGenerationEira(EiraDataModel);
          chartName = "Daily Generation";
          tableData = responseData;
        } catch (e) {
          console.error(e);
        }
      } else if (graphName === "CO2 Avoidance") {
        let responseData = await CO2Avioded(EiraDataModel);
        responseData = responseData.map((data) => {
          return {
            ...data,
            trees: CalculateTreesNeeded(data.co2),
          };
        });
        console.log(responseData);

        tableData = responseData;
      } else if (graphName === "Active Power") {
        const dataModel = {
          fromDate: from,
          graphType: "Specific Yield",
          siteId: siteIdFilter[0]?.siteId,
          toDate: to,
          parameters: ["ActivePower"],
          range: "custom",
          equipmentIds: meter,
          timeperiod: "Today",
          intervalMins: dataFrequency,
          energyGenBasedOn: siteIdFilter[0]?.energyGenBasedOn,
          energyFlag: 0,
        };

        let responseData = [];
        responseData = await fetchParameterComparisonEira(dataModel);
        // console.log(equipmentParameter);
        const dataFields = ["ActivePower"];
        const seriesData = [];
        // console.log(equipments);

        dataFields.forEach(async (field) => {
          responseData.forEach(async (data) => {
            // console.log(field);

            let equipmentName = equipDetails?.filter((dataValue) => {
              return (
                dataValue.equipmentId ===
                Number(data.equipmentId ? data.equipmentId : data.EquipmentId)
              );
            });
            // console.log(equipmentName);

            const timeStampData = data.timeStamp
              ? data.timeStamp
              : data.TimeStamp;

            const timeStamp = new Date(timeStampData).getTime();

            // console.log(timeStamp);

            const value = data[field];
            const seriesName = `${equipmentName[0]?.displayName} - ${field}`;
            // console.log(value);

            const existingSeries = seriesData.find(
              (series) => series.name === seriesName
            );
            if (existingSeries) {
              existingSeries.data.push([timeStamp, value]);
            } else {
              const newSeries = {
                name: seriesName,
                type: "line",
                yAxis: 6,
                data: [[timeStamp, value]],
              };
              seriesData.push(newSeries);
              // console.log(seriesData);

              let filteredArray = seriesData.filter((obj) => {
                return !obj.data.every(([_, value]) => isNaN(value));
              });
              multichartData = filteredArray;
              return filteredArray;
            }
          });
        });
        chartName = "Active Power";
        tableData = responseData;
      } else if (graphName === "Actual vs Expected") {
        let responseData = await ActualVsExpected(EiraDataModel);

        setLoading(false);
        tableData = responseData;
        chartName = "Actual vs Expected";
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
    return { chartName, tableData, multichartData };
  };

  function CalculateTreesNeeded(carbonAvoided) {
    // CO₂ absorbed by one tree annually in kg
    const co2AbsorptionPerTree = 22;

    // Convert carbonAvoided from tons to kilograms
    const carbonAvoidedKg = carbonAvoided * 1000;

    // Calculate the number of trees needed
    const treesNeeded = carbonAvoidedKg / co2AbsorptionPerTree;

    return Math.ceil(treesNeeded); // Round up to the nearest whole number
  }

  const dailyGenData = dailyData;

  const monthlyGenData = useGenerateSeries(monthlyData, [
    {
      key: "todayEnergy",
      displayName: "Today Energy",
      type: "column",
      axis: 0,
    },
    { key: "irradiation", displayName: "Irradiation", type: "line", axis: 1 },
  ]);

  const treePlantedData = useGenerateSeries(yearlyData, [
    {
      key: "trees",
      displayName: "Tree planted",
      type: "line",
      axis: 5,
    },
  ]);
  const actualVsExceptedData = useGenerateSeries(actualVsExcepted, [
    {
      key: "todayEnergy",
      displayName: "Today Energy",
      type: "column",
      axis: 0,
    },
    {
      key: "exceptedEnergy",
      displayName: "Expected Energy",
      type: "column",
      axis: 0,
      color: "rgb(213, 104, 251)",
    },
    {
      key: "irradiation",
      displayName: "Irradiation",
      type: "line",
      axis: 1,
      color: "rgb(84, 79, 197)",
    },
  ]);

  const totalGenData = useGenerateSeries(totalData, [
    {
      key: "todayEnergy",
      displayName: "Today Energy",
      type: "column",
      axis: 0,
    },
  ]);

  function ConvertToSource(source, carbonAvoided) {
    // CO₂ emission factors (in kg CO₂ per unit)
    const emissionFactors = {
      fuelOil: 2.52, // kg CO₂ per liter
      naturalGas: 1.93, // kg CO₂ per cubic meter
      coal: 2210, // kg CO₂ per ton
    };

    // Convert carbonAvoided from tons to kilograms
    const carbonAvoidedKg = carbonAvoided * 1000;

    // Calculate the avoided fuel based on the selected source
    switch (source.toLowerCase()) {
      case "fuel oil":
        return (carbonAvoidedKg / emissionFactors.fuelOil).toFixed(2) + " L";
      case "natural gas":
        return (
          (carbonAvoidedKg / emissionFactors.naturalGas).toFixed(2) + " m³"
        );
      case "coal":
        return (carbonAvoidedKg / emissionFactors.coal).toFixed(2) + " t";
      default:
        return "Invalid source. Please select 'fuel oil', 'natural gas', or 'coal'.";
    }
  }

  const isLightMode = mode === "light";

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: isLightMode ? "white" : "#121212",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: "#544fc5",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "100dvh",
          width: "99dvw",
          overflow: "auto",
          padding: "5px",
          backgroundColor: isLightMode ? "#f1f1f1" : "black",
        }}
      >
        <Grid2 container spacing={0}>
          <Grid2 size={14} sx={{ margin: "8px" }}>
            <Card
              elevation={0}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5px",
                backgroundColor: isLightMode ? "white" : "#121212",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    fontSize: "2em",
                    fontWeight: "400",
                    color: "#544fc5",
                  }}
                >
                  Dashboard
                </Typography>
                <div
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "400",
                    color: "#1773A6",
                  }}
                >
                  The sustainable energy produced by this facility
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <Tooltip
                    title={site}
                    disableFocusListener
                    enterDelay={200}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -10],
                            },
                          },
                        ],
                      },
                    }}
                    arrow
                  >
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      value={site}
                      options={siteList.map((option) => option.siteName)}
                      onChange={(data, event) => handleSite(data, event)}
                      sx={{
                        width: "18vw",
                        "& .MuiAutocomplete-inputRoot": {
                          color: "#1773A6",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#1773A6",
                          },
                        },
                      }}
                      slots={{
                        paper: (props) => (
                          <Paper
                            sx={{
                              color: "#1773A6",
                              backgroundColor: isLightMode
                                ? "white"
                                : "#121212",
                            }}
                            {...props}
                          />
                        ),
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select Site ..." />
                      )}
                      classes={{ option: "autocomplete" }}
                    />
                  </Tooltip>
                </div>

                <div style={{ cursor: "pointer", marginLeft: "10px" }}>
                  <Tooltip title={"Home"} userSelect="none" followCursor>
                    <IconButton
                      onClick={() => {
                        navigate("/menu");
                      }}
                    >
                      <HomeOutlinedIcon
                        sx={{ color: "#1773A6", fontSize: "24px" }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>

                <div style={{ cursor: "pointer" }}>
                  <Tooltip
                    title={mode === "light" ? "Dark Mode" : "Light Mode"}
                    userSelect="none"
                    followCursor
                  >
                    <IconButton
                      onClick={() => {
                        toggleColorMode();
                      }}
                    >
                      {mode === "light" ? (
                        <DarkModeOutlinedIcon
                          sx={{ color: "#1773A6", fontSize: "24px" }}
                        />
                      ) : (
                        <LightModeOutlinedIcon
                          sx={{ color: "#1773A6", fontSize: "24px" }}
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Grid2>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
                width: "100vw",
              }}
            >
              <FadeLoader color="blue" radius={20} loading={loading} />
            </div>
          ) : (
            <Grid2 size={14} sx={{ margin: "8px" }}>
              <Grid2 container rowSpacing={1} columnSpacing={1}>
                <Grid2 size={4}>
                  <Card
                    elevation={0}
                    sx={{
                      display: "flex",
                      padding: "5px",
                      width: "31.5dvw",
                      height: "42dvh",
                      backgroundColor: isLightMode ? "white" : "#121212",
                    }}
                  >
                    <LargeChartEiraVersion
                      tableData={dailyData}
                      chartName={"Active Power"}
                      fallBack={fallBack}
                      WidgetName={`Daily Generation (${moment().format(
                        "DD-MM-YYYY"
                      )})`}
                      seriesData={dailyGenData}
                      mode={mode}
                    />
                  </Card>
                </Grid2>
                <Grid2 size={4}>
                  <Card
                    elevation={0}
                    sx={{
                      display: "flex",
                      padding: "5px",
                      width: "31.5dvw",
                      height: "42dvh",
                      backgroundColor: isLightMode ? "white" : "#121212",
                    }}
                  >
                    <LargeChartEiraVersion
                      tableData={actualVsExcepted}
                      chartName={"Daily Generation"}
                      fallBack={fallBack}
                      WidgetName={`Actual vs Expected (7 days)`}
                      seriesData={actualVsExceptedData}
                      mode={mode}
                    />
                  </Card>
                </Grid2>
                <Grid2 size={4}>
                  <Card
                    elevation={0}
                    sx={{
                      display: "flex",
                      padding: "5px",
                      width: "31.5dvw",
                      height: "42dvh",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: isLightMode ? "white" : "#121212",
                    }}
                  >
                    {/* <LargeChartEiraVersion
                      tableData={yearlyData}
                      chartName={"Daily Generation"}
                      fallBack={fallBack}
                      WidgetName={`Tree Plantations (${moment()
                        .subtract(1, "year")
                        .format("YYYY")})`}
                      seriesData={treePlantedData}
                      mode={mode}
                    /> */}
                    <LargeChartEiraVersion
                      tableData={monthlyData}
                      chartName={"Daily Generation"}
                      fallBack={fallBack}
                      WidgetName={`Monthly Overview (${moment().format(
                        "MMMM YYYY"
                      )})`}
                      seriesData={monthlyGenData}
                      mode={mode}
                    />
                  </Card>
                </Grid2>
                <Grid2 size={4}>
                  <Card
                    elevation={0}
                    sx={{
                      display: "flex",
                      padding: "5px",
                      width: "31.5dvw",
                      height: "42dvh",
                      backgroundColor: isLightMode ? "white" : "#121212",
                    }}
                  >
                    <LargeChartEiraVersion
                      tableData={totalData}
                      chartName={"Daily Generation"}
                      fallBack={fallBack}
                      WidgetName={`Total Generation`}
                      seriesData={totalGenData}
                      mode={mode}
                    />
                  </Card>
                </Grid2>
                <Grid2 size={4}>
                  <Card
                    elevation={0}
                    sx={{
                      display: "flex",
                      padding: "5px",
                      width: "31.5dvw",
                      height: "42dvh",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: isLightMode ? "white" : "#121212",
                    }}
                  >
                    <Card
                      elevation={0}
                      sx={{
                        padding: "5px",
                        // width: "31.5dvw",
                        height: "42dvh",
                        backgroundColor: isLightMode ? "white" : "#121212",
                      }}
                    >
                      <Grid2>
                        <Grid2
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "1.5em",
                              fontWeight: "normal",
                              color: "#544fc5",
                              fill: "#544fc5",
                              marginTop: "5px",
                            }}
                          >
                            Weather Forecast
                          </Typography>
                        </Grid2>
                        <Grid2
                          sx={{
                            display: "flex",
                            padding: "10px",
                            justifyContent: "center",
                            marginTop: "20px",
                            height: "250px",
                          }}
                        >
                          <Grid2
                            size={6}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: "20px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "1em",
                                fontWeight: "normal",
                                color: "#1773A6",
                                fill: "#1773A6",
                              }}
                            >
                              {moment(forecastData?.daily?.time[0]).format(
                                "dddd"
                              )}
                            </Typography>
                            <div
                              style={{
                                // width: "200px",
                                height: "150px",
                                padding: "10px",
                                objectFit: "contain",
                              }}
                            >
                              <img
                                src={getImageUrl(
                                  forecastData?.daily?.weather_code[0]
                                )}
                                alt={moment(
                                  forecastData?.daily?.time[0]
                                ).format("dddd")}
                              />
                            </div>
                            <Typography
                              sx={{
                                fontSize: "1em",
                                fontWeight: "normal",
                                color: "#1773A6",
                                fill: "#1773A6",
                                marginBottom: "10px",
                              }}
                            >
                              {getDescription(
                                forecastData?.daily?.weather_code[0]
                              )}
                            </Typography>
                            {/* <Typography
                              sx={{
                                fontSize: "0.8em",
                                fontWeight: "600",
                                color: "#544fc5",
                                fill: "#544fc5",
                                marginBottom: "5px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "1em",
                                  fontWeight: "600",
                                  color: "#1773A6",
                                  fill: "#1773A6",
                                }}
                              >
                                Air Temp :{" "}
                              </span>
                              {
                                getTempRangeString(
                                  forecastData?.daily,
                                  forecastData?.daily_units
                                )[0]
                              }
                            </Typography> */}
                            <Typography
                              sx={{
                                fontSize: "1em",
                                fontWeight: "600",
                                color: "#544fc5",
                                fill: "#544fc5",
                                marginBottom: "5px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "1em",
                                  fontWeight: "600",
                                  color: "#1773A6",
                                  fill: "#1773A6",
                                }}
                              >
                                Temp :{" "}
                              </span>
                              {getApparentTempRangeString(
                                forecastData?.daily,
                                forecastData?.daily_units
                              )[0] ?? 0}
                            </Typography>
                          </Grid2>
                          <Grid2
                            size={6}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: "20px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "1em",
                                fontWeight: "normal",
                                color: "#1773A6",
                                fill: "#1773A6",
                              }}
                            >
                              {moment(forecastData?.daily?.time[1]).format(
                                "dddd"
                              )}
                            </Typography>
                            <div
                              style={{
                                // width: "200px",
                                height: "150px",
                                padding: "10px",
                                objectFit: "contain",
                              }}
                            >
                              <img
                                src={getImageUrl(
                                  forecastData?.daily?.weather_code[1]
                                )}
                                alt={moment(
                                  forecastData?.daily?.time[1]
                                ).format("dddd")}
                              />
                            </div>
                            <Typography
                              sx={{
                                fontSize: "1em",
                                fontWeight: "normal",
                                color: "#1773A6",
                                fill: "#1773A6",
                                marginBottom: "10px",
                              }}
                            >
                              {getDescription(
                                forecastData?.daily?.weather_code[1]
                              )}
                            </Typography>
                            {/* <Typography
                              sx={{
                                fontSize: "0.8em",
                                fontWeight: "600",
                                color: "#544fc5",
                                fill: "#544fc5",
                                marginBottom: "5px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "1em",
                                  fontWeight: "600",
                                  color: "#1773A6",
                                  fill: "#1773A6",
                                }}
                              >
                                Air Temp :{" "}
                              </span>
                              {
                                getTempRangeString(
                                  forecastData?.daily,
                                  forecastData?.daily_units
                                )[1]
                              }
                            </Typography> */}
                            <Typography
                              sx={{
                                fontSize: "1em",
                                fontWeight: "600",
                                color: "#544fc5",
                                fill: "#544fc5",
                                marginBottom: "5px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "1em",
                                  fontWeight: "600",
                                  color: "#1773A6",
                                  fill: "#1773A6",
                                }}
                              >
                                Temp :{" "}
                              </span>
                              {getApparentTempRangeString(
                                forecastData?.daily,
                                forecastData?.daily_units
                              )[1] ?? 0}
                            </Typography>
                          </Grid2>
                          <Grid2
                            size={6}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: "20px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "1em",
                                fontWeight: "normal",
                                color: "#1773A6",
                                fill: "#1773A6",
                              }}
                            >
                              {moment(forecastData?.daily?.time[2]).format(
                                "dddd"
                              )}
                            </Typography>
                            <div
                              style={{
                                // width: "200px",
                                height: "150px",
                                padding: "10px",
                                objectFit: "contain",
                              }}
                            >
                              <img
                                src={getImageUrl(
                                  forecastData?.daily?.weather_code[2]
                                )}
                                alt={moment(
                                  forecastData?.daily?.time[2]
                                ).format("dddd")}
                              />
                            </div>
                            <Typography
                              sx={{
                                fontSize: "1em",
                                fontWeight: "normal",
                                color: "#1773A6",
                                fill: "#1773A6",
                                marginBottom: "10px",
                              }}
                            >
                              {getDescription(
                                forecastData?.daily?.weather_code[2]
                              )}
                            </Typography>
                            {/* <Typography
                              sx={{
                                fontSize: "0.8em",
                                fontWeight: "600",
                                color: "#544fc5",
                                fill: "#544fc5",
                                marginBottom: "5px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "1em",
                                  fontWeight: "600",
                                  color: "#1773A6",
                                  fill: "#1773A6",
                                }}
                              >
                                Air Temp :{" "}
                              </span>
                              {
                                getTempRangeString(
                                  forecastData?.daily,
                                  forecastData?.daily_units
                                )[2]
                              }
                            </Typography> */}
                            <Typography
                              sx={{
                                fontSize: "1em",
                                fontWeight: "600",
                                color: "#544fc5",
                                fill: "#544fc5",
                                marginBottom: "5px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "1em",
                                  fontWeight: "600",
                                  color: "#1773A6",
                                  fill: "#1773A6",
                                }}
                              >
                                Temp :{" "}
                              </span>
                              {getApparentTempRangeString(
                                forecastData?.daily,
                                forecastData?.daily_units
                              )[2] ?? 0}
                            </Typography>
                          </Grid2>
                        </Grid2>
                      </Grid2>
                    </Card>
                  </Card>
                </Grid2>
                <Grid2 size={4}>
                  <Card
                    elevation={0}
                    sx={{
                      padding: "5px",
                      // width: "31.5dvw",
                      height: "42dvh",
                      backgroundColor: isLightMode ? "white" : "#121212",
                    }}
                  >
                    <Grid2>
                      <Grid2
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1.5em",
                            fontWeight: "normal",
                            color: "#544fc5",
                            fill: "#544fc5",
                            marginTop: "5px",
                          }}
                        >
                          Carbon Offset
                        </Typography>
                      </Grid2>
                      <Grid2
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography sx={{ color: "#1773A6" }}>
                          CO2 avodiance since
                          <span
                            style={{
                              marginLeft: "4px",
                              fontWeight: "600",
                              color: "#544fc5",
                            }}
                          >
                            {co2AvoidedData.date}
                          </span>
                        </Typography>
                      </Grid2>
                      <Grid2
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography sx={{ color: "#1773A6" }}>
                          <span
                            style={{
                              marginRight: "4px",
                              fontWeight: "600",
                              color: "#544fc5",
                            }}
                          >
                            {co2AvoidedData.total} t.
                          </span>
                          This equals
                        </Typography>
                      </Grid2>
                      <Grid2
                        sx={{
                          display: "flex",
                          padding: "10px",
                          justifyContent: "center",
                          marginTop: "20px",
                          height: "250px",
                        }}
                      >
                        <Grid2
                          size={4}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "20px",
                          }}
                        >
                          <div style={{ padding: "20px" }}>
                            <img
                              src={oilDrum}
                              alt="Oil Drum"
                              style={{ width: "120px" }}
                            />
                          </div>
                          <Typography
                            sx={{
                              fontSize: "1em",
                              fontWeight: "600",
                              color: "#544fc5",
                              fill: "#544fc5",
                            }}
                          >
                            {ConvertToSource("fuel oil", co2AvoidedData.total)}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "1em",
                              fontWeight: "normal",
                              color: "#1773A6",
                              fill: "#1773A6",
                            }}
                          >
                            Fuel Oil
                          </Typography>
                        </Grid2>
                        <Grid2
                          size={1}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "1.2em",
                              fontWeight: "normal",
                              color: "#1773A6",
                              fill: "#1773A6",
                              marginTop: "5px",
                            }}
                          >
                            Or
                          </Typography>
                        </Grid2>
                        <Grid2
                          size={4}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ padding: "20px" }}>
                            <img
                              src={GasPipe}
                              alt="Gas Pipe"
                              style={{ width: "120px" }}
                            />
                          </div>
                          <Typography
                            sx={{
                              fontSize: "1em",
                              fontWeight: "600",
                              color: "#544fc5",
                              fill: "#544fc5",
                            }}
                          >
                            {ConvertToSource(
                              "natural gas",
                              co2AvoidedData.total
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "1em",
                              fontWeight: "normal",
                              color: "#1773A6",
                              fill: "#1773A6",
                            }}
                          >
                            Natural Gas
                          </Typography>
                        </Grid2>
                        <Grid2
                          size={1}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "1.2em",
                              fontWeight: "normal",
                              color: "#1773A6",
                              fill: "#1773A6",
                              marginTop: "5px",
                            }}
                          >
                            Or
                          </Typography>
                        </Grid2>
                        <Grid2
                          size={4}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ padding: "20px" }}>
                            <img
                              src={CoalCart}
                              alt="Coal Cart"
                              style={{ width: "120px" }}
                            />
                          </div>
                          <Typography
                            sx={{
                              fontSize: "1em",
                              fontWeight: "600",
                              color: "#544fc5",
                              fill: "#544fc5",
                            }}
                          >
                            {ConvertToSource("coal", co2AvoidedData.total)}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "1em",
                              fontWeight: "normal",
                              color: "#1773A6",
                              fill: "#1773A6",
                            }}
                          >
                            Hard Coal
                          </Typography>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  </Card>
                </Grid2>
              </Grid2>
            </Grid2>
          )}
        </Grid2>
      </Box>
    </>
  );
};
